import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import isEmail from 'validator/lib/isEmail';
import isPostalCode from 'validator/lib/isPostalCode';
import { COUNTRIES, DEFAULT_DATE_FORMAT } from '../Constants';
import { safeStringCompare } from '../Utils/index';

export const ERROR_CODES = {
  XHR_FAILED: 'xhrFailed',
  // TODO: Replace with specific invalid codes following format of
  // INVALID_<field>_<validation>: <ErrorKey>
  INVALID: 'invalid',
  REQUIRED: 'required',
  VALID: '',
  CANCEL_FAILED: 'CancelFailed',
  REMOVE_FAILED: 'RemoveFailed',
  PASSWORD_MISMATCH: 'passwordsDoNotMatch',
  PASSWORD_INVALID: 'PasswordInvalid',
  PASSWORD_REQUIRED: 'PasswordRequired',
  BOOKING_NUMBER_REQUIRED: 'BookingNumberRequired',
  LAST_NAME_REQUIRED: 'LastNameRequired',
  INVALID_EMAIL_FORMAT: 'EmailInvalid',
  INVALID_DATE: 'DateInvalid',
  INVALID_DATES: 'DatesInvalid',
  INVALID_DEVIATION_DATE: 'InvalidDeviationDate',
  EMAIL_REQUIRED: 'EmailRequired',
  INVALID_EMAIL_LENGTH: 'EmailLengthInvalid',
  INVALID_BIRTHDATE_FORMAT: 'BirthDateFormatInvalid',
  INVALID_BIRTHDATE_FORMAT_UK_AU: 'BirthDateFormatInvalidUKAU',
  INVALID_DATE_AGE: 'AgeInvalid',
  INVALID_PASSPORT_ISSUED: 'PassportIssuedDateInvalid',
  INVALID_PASSPORT_ISSUED_UK_AU: 'PassportIssuedDateInvalidUKAU',
  INVALID_PASSPORT_EXPIRES: 'PassportExpirationInvalid',
  INVALID_PASSPORT_EXPIRES_UK_AU: 'PassportExpirationInvalidUKAU',
  INVALID_PASSPORT_WILL_EXPIRE: 'PassportWillExpire',
  INVALID_PASSPORT_NUMBER: 'PassportNumberInvalid',
  INVALID_PASSPORT_NUMBER_LENGTH: 'PassportNumberLengthInvalid',
  INVALID_ADDRESS_LINE_1: 'AddressLine1Invalid',
  INVALID_ADDRESS_LINE_2: 'AddressLine2Invalid',
  INVALID_CITY_LENGTH: 'CityInvalid',
  INVALID_EMERGENCY_NAME_FORMAT: 'EmergencyContactNameInvalid',
  INVALID_EMERGENCY_ADDRESS_LINE_1: 'EmergencyContactAddress1Invalid',
  INVALID_EMERGENCY_ADDRESS_LINE_2: 'EmergencyContactAddress2Invalid',
  INVALID_EMERGENCY_RELATION_FORMAT: 'EmergencyContactRelationInvalid',
  INVALID_PHONE_NUMBER: 'PhoneNumberInvalidGeneral',
  INVALID_PHONE_NUMBER_US_CA: 'PhoneNumberInvalidUSCA',
  INVALID_PHONE_NUMBER_UK_AU: 'PhoneNumberInvalidUKAU',
  INVALID_MOBILE_NUMBER: 'MobileNumberInvalidGeneral',
  INVALID_MOBILE_NUMBER_US_CA: 'MobileNumberInvalidUSCA',
  INVALID_MOBILE_NUMBER_UK_AU: 'MobileNumberInvalidUKAU',
  INVALID_ZIP_FORMAT_US: 'PostalCodeInvalidUS',
  INVALID_ZIP_FORMAT_CA: 'PostalCodeInvalidCA',
  INVALID_ZIP_FORMAT_UK: 'PostalCodeInvalidUK',
  INVALID_ZIP_FORMAT_AUNZ: 'PostalCodeInvalidAUNZ',
  INVALID_ZIP_FORMAT: 'PostalCodeInvalid',
  INVALID_ZIP_LENGTH: 'PostalCodeInvalidLength',
  ZIP_REQUIRED_US: 'PostalCodeRequiredUS',
  ZIP_REQUIRED_CA: 'PostalCodeRequiredCA',
  ZIP_REQUIRED_UK: 'PostalCodeRequiredUK',
  ZIP_REQUIRED_AUNZ: 'PostalCodeRequiredAUNZ',
  STATE_REQUIRED_US: 'StateRequiredUS',
  STATE_REQUIRED_CA: 'StateRequiredCA',
  STATE_REQUIRED_UK: 'StateRequiredUK',
  STATE_REQUIRED_AU: 'StateRequiredAU',
  STATE_REQUIRED_NZ: 'StateRequiredNZ',
  INVALID_SAIL_DATE: 'SailDateInvalid',
  INVALID_MINIMUM_PAYMENT: 'MinimumPaymentValidation',
  MINIMUM_PAYMENT_CANNOT_BE_ZERO: 'MinimumPaymentCannotBeZero',
  INVALID_MAXIMUM_PAYMENT: 'MaximumPaymentValidation',
  INVALID_FREQUENT_FLYER_NUMBER: 'FrequentFlyerNumberRequired',
  INVALID_FREQUENT_FLYER_AIRLINE: 'FrequentFlyerAirlineRequired',
  UPDATE_FAILED: 'UpdateFailed',
  DUPLICATE_INVITEE: 'CannotInviteSameInvitee',
  OVER_MAX_TABLE_SIZE: 'OverMaxTableSize',
  SPA_NO_AVAILABILITY: 'SpaNoAvailableTimes',
  WARNING_PASSPORT_EXPIRES_SOON: 'PassportWillExpireSoon',
  INCORRECT_EMAIL: 'IncorrectEmailAddress',
  INVALID_DUPLICATE_AIRLINE: 'DuplicateAirline',
  INVALID_DUPLICATE_FFNUMBER: 'DuplicateFrequentFlyerNumber',
  INVALID_FUTURE_VACCINATION: 'FutureVaccination',
  INVALID_PAST_VACCINATION: 'PastVaccinationDate',
  PROCESS_FAILED: 'ProcessFailed',
  INVALID_LICENSE_EXPIRES: 'LicenseExpirationInvalid',
  INVALID_LICENSE_EXPIRED: 'LicenseExpired',
  INVALID_LICENSE_NUMBER: 'LicenseNumberInvalid',
  INVALID_LICENSE_WILL_EXPIRE: 'LicenseWillExpire',
  WARNING_LICENSE_EXPIRES_SOON: 'LicenseWillExpireSoon',
  INVALID_INSURANCE_PROVIDER_LENGTH: 'InsuranceProviderLengthInvalid',
  INVALID_INSURANCE_POLICY_NUMBER_LENGTH: 'InsurancePolicyNumberLengthInvalid',
  INVALID_DISEMBARK_MONTH_YEAR: 'ExpDisembarkMonthYearInvalid',
  CVV_REQUIRED: 'CvvRequired',
  INVALID_CVV: 'InvalidCvv',
  STATE_REQUIRED: 'StateRequired',
};

const {
  INVALID,
  REQUIRED,
  VALID,
  PASSWORD_MISMATCH,
  PASSWORD_INVALID,
  INVALID_EMAIL_FORMAT,
  INVALID_EMAIL_LENGTH,
  INVALID_BIRTHDATE_FORMAT,
  INVALID_BIRTHDATE_FORMAT_UK_AU,
  INVALID_DATE_AGE,
  INVALID_DATE,
  INVALID_DATES,
  INVALID_PASSPORT_ISSUED,
  INVALID_PASSPORT_ISSUED_UK_AU,
  INVALID_PASSPORT_EXPIRES,
  INVALID_PASSPORT_EXPIRES_UK_AU,
  INVALID_PASSPORT_WILL_EXPIRE,
  INVALID_PASSPORT_NUMBER,
  INVALID_PASSPORT_NUMBER_LENGTH,
  INVALID_ADDRESS_LINE_1,
  INVALID_ADDRESS_LINE_2,
  INVALID_CITY_LENGTH,
  INVALID_EMERGENCY_NAME_FORMAT,
  INVALID_EMERGENCY_ADDRESS_LINE_1,
  INVALID_EMERGENCY_ADDRESS_LINE_2,
  INVALID_EMERGENCY_RELATION_FORMAT,
  INVALID_PHONE_NUMBER,
  INVALID_PHONE_NUMBER_US_CA,
  INVALID_PHONE_NUMBER_UK_AU,
  INVALID_MOBILE_NUMBER,
  INVALID_MOBILE_NUMBER_US_CA,
  INVALID_MOBILE_NUMBER_UK_AU,
  INVALID_ZIP_FORMAT_US,
  INVALID_ZIP_FORMAT_CA,
  INVALID_ZIP_FORMAT_UK,
  INVALID_ZIP_FORMAT_AUNZ,
  INVALID_ZIP_FORMAT,
  INVALID_ZIP_LENGTH,
  ZIP_REQUIRED_US,
  ZIP_REQUIRED_CA,
  ZIP_REQUIRED_UK,
  ZIP_REQUIRED_AUNZ,
  STATE_REQUIRED_US,
  STATE_REQUIRED_CA,
  STATE_REQUIRED_UK,
  STATE_REQUIRED_AU,
  STATE_REQUIRED_NZ,
  INVALID_SAIL_DATE,
  INVALID_MINIMUM_PAYMENT,
  MINIMUM_PAYMENT_CANNOT_BE_ZERO,
  INVALID_MAXIMUM_PAYMENT,
  WARNING_PASSPORT_EXPIRES_SOON,
  INVALID_DUPLICATE_AIRLINE,
  INVALID_DUPLICATE_FFNUMBER,
  INVALID_FUTURE_VACCINATION,
  INVALID_PAST_VACCINATION,
  INVALID_LICENSE_EXPIRES,
  INVALID_LICENSE_EXPIRED,
  INVALID_LICENSE_NUMBER,
  INVALID_LICENSE_WILL_EXPIRE,
  WARNING_LICENSE_EXPIRES_SOON,
  INVALID_INSURANCE_PROVIDER_LENGTH,
  INVALID_INSURANCE_POLICY_NUMBER_LENGTH,
  INVALID_DISEMBARK_MONTH_YEAR,
  INVALID_CVV,
  CVV_REQUIRED,
  STATE_REQUIRED,
} = ERROR_CODES;

// Helper validation functions
const dateValidator = (format, error) => (value) => {
  const date = moment(value, format, true);
  if (!value || (date.isValid() && date.isAfter(moment('19000101', DEFAULT_DATE_FORMAT.YEAR_FIRST)))) {
    return VALID;
  }
  return error;
};

export const sailDateValid = (value) => dateValidator(DEFAULT_DATE_FORMAT.NA, INVALID_SAIL_DATE)(value);

const lengthValidator = ({ noValue, error, min = 0, max }) => (value) => {
  if (!value) {
    return noValue;
  }
  return value.length >= min && value.length <= max ? VALID : error;
};

// General validators
export const required = (value = '') => {
  if (!value) {
    return REQUIRED;
  }

  if (Number.isFinite(value)) {
    return VALID;
  }

  if (value instanceof Date) {
    if (Number.isNaN(value.getTime())) {
      return REQUIRED;
    }
    return VALID;
  }
  return isEmpty(value) ? REQUIRED : VALID;
};

export const requiredPostalCode = (value = '', allValues) => {
  const country = getCountryFromValues(allValues);

  const postalCodeInvalidErrors = {
    US: ZIP_REQUIRED_US,
    CA: ZIP_REQUIRED_CA,
    UK: ZIP_REQUIRED_UK,
    GB: ZIP_REQUIRED_UK,
    AU: ZIP_REQUIRED_AUNZ,
    NZ: ZIP_REQUIRED_AUNZ,
  };

  return required(value, allValues) === REQUIRED ? postalCodeInvalidErrors[country] : VALID;
};

export const requiredState = (value = '', allValues) => {
  const country = getCountryFromValues(allValues);

  const stateInvalidErrors = {
    US: STATE_REQUIRED_US,
    CA: STATE_REQUIRED_CA,
    UK: STATE_REQUIRED_UK,
    GB: STATE_REQUIRED_UK,
    AU: STATE_REQUIRED_AU,
    NZ: STATE_REQUIRED_NZ,
  };

  return required(value, allValues) === REQUIRED ? stateInvalidErrors[country] : VALID;
};

export const checked = (value = false) => (!value ? REQUIRED : VALID);

// eslint-disable-next-line
export const passwordMatch = (value = '', allValues = {}) =>
  value === allValues.newPassword ? VALID : PASSWORD_MISMATCH;

export const email = (address = '') => (isEmail(address) ? VALID : INVALID_EMAIL_FORMAT);

export const validAddressLineCheck = (error) => (value) => {
  if (!value) {
    return VALID;
  }
  return value.match(/^[#,./\s\d\-A-Z]{2,35}$/i) ? VALID : error;
};

// ACH Validations

// ACH forms have the country at different locations,
// so this helper function handles both cases
const getCountryFromValues = (values) => {
  let { country } = values;

  if (!country) {
    const { account } = values;
    if (account) {
      ({ country } = account);
    } else {
      const { defaultCountry } = values;
      country = defaultCountry;
    }
  }

  // isPostalCode which this helper function is used for does not accept UK as country, required GB
  return normalizeCountryCode(country);
};

export const normalizeCountryCode = (country, ukCode = 'GB') => {
  if (['UK', 'GB'].includes(country)) {
    country = ukCode;
  }
  return (country || '').toUpperCase();
};

const getIsFormCountryUKAUNZ = (values) => {
  const { country } = values;
  return [COUNTRIES.UNITED_KINGDOM, COUNTRIES.UNITED_KINGDOM_GB, COUNTRIES.AUSTRALIA, COUNTRIES.NEW_ZEALAND].includes(
    country
  );
};

export const validBankRoutingNumber = (value, allValues) => {
  const country = getCountryFromValues(allValues);

  let length;
  switch (country) {
    case COUNTRIES.CANADA:
      length = 8;
      break;
    case COUNTRIES.UNITED_STATES:
      length = 9;
      break;
    default:
      return INVALID;
  }
  const regex = new RegExp(`^[\\d]{${length}}$`);

  const isAllSame = (v) => v.length > 1 && v.replace(`/${v.slice(0, 1)}/g`, '').length < 1;

  return value && value.match(regex) && !isAllSame(value) ? VALID : INVALID;
};

// Credit card validations
export const validExpiryDate = (value) => {
  if (!value) {
    return VALID;
  }

  const { month, year } = value;
  if (!(month && year)) {
    return VALID;
  }

  const expiryDate = moment(month + year, 'MMYY');
  return expiryDate.isSameOrAfter(moment(), 'month') ? VALID : INVALID;
};

export const validOnboardCreditCardDate = (value, allValues, props) => {
  if (!value) {
    return VALID;
  }

  const { disembarkDate } = props?.guests?.[0] || '';
  if (!disembarkDate) {
    return VALID;
  }
  const { month, year } = value;
  if (!(month && year)) {
    return VALID;
  }

  const expiryDate = moment(month + year, 'MMYY');
  return expiryDate.isSameOrAfter(moment(disembarkDate), 'month') ? VALID : INVALID_DISEMBARK_MONTH_YEAR;
};

// eslint-disable-next-line
export const validNameOnCard = (value) =>
  value.match(/^[ '\-A-Z]{2,50}$/i) && value.trim().length > 0 ? VALID : INVALID;

export const validCvvOnCard = (value, allValues) => {
  const cardType = allValues?.account?.cardType;
  if (!value) {
    return CVV_REQUIRED;
  }
  if ((cardType !== 'AX' && value?.length === 3) || (cardType === 'AX' && value?.length === 4)) {
    return VALID;
  }
  return INVALID_CVV;
};

// Address validations
export const validAddressLine = (value) => {
  if (!value) {
    return VALID;
  }
  return value.match(/^[#,./\s\d\-A-Z]{2,30}$/i) && value.trim().length > 0 ? VALID : INVALID;
};

export const validCity = lengthValidator({
  noValue: INVALID,
  error: INVALID,
  min: 2,
  max: 20,
});

function isValidUKPostcode(code) {
  const postcodeRegEx = /[A-Z]{1,2}[A-Z0-9]{1,2} ?[0-9][A-Z]{2}/i;
  return postcodeRegEx.test(code);
}

export const validPostalCode = (value, allValues, props, name) => {
  const mainCountries = Object.values(COUNTRIES);
  let country =
    normalizeCountryCode(allValues.emergencyContactCountryCode) || normalizeCountryCode(allValues.defaultCountry);

  if (['zip'].includes(name)) {
    country = getCountryFromValues(allValues);
  }

  if (!mainCountries.includes(country)) {
    return VALID;
  }

  const postalCodeInvalidErrors = {
    US: INVALID_ZIP_FORMAT_US,
    CA: INVALID_ZIP_FORMAT_CA,
    UK: INVALID_ZIP_FORMAT_UK,
    GB: INVALID_ZIP_FORMAT_UK,
    AU: INVALID_ZIP_FORMAT_AUNZ,
    NZ: INVALID_ZIP_FORMAT_AUNZ,
  };
  const invalidError = postalCodeInvalidErrors[country] || INVALID_ZIP_FORMAT;

  const validator = {
    GB: () => (isPostalCode(value, country) && isValidUKPostcode(value) ? VALID : invalidError),
    NZ: () => (isPostalCode(value, 'AU') ? VALID : invalidError),
  };

  if (validator?.[country]) {
    return validator[country]();
  }

  return isPostalCode(value, country) ? VALID : invalidError;
};

// shoreEx validation
export const validTime = (value, allValues, props, name) => {
  const { times } = props;
  if (value) {
    const passengerNum = name.includes('1') ? 0 : 1;
    const time = times.find((slot) => slot.value === value);
    return time.passengerConflicts[passengerNum] ? INVALID : VALID;
  }
  return VALID;
};

// GIF Guest and Emergency Contact validation
export const validBirthDate = dateValidator(DEFAULT_DATE_FORMAT.NA, INVALID_BIRTHDATE_FORMAT);
export const validBirthDateUKAU = dateValidator(DEFAULT_DATE_FORMAT.EU, INVALID_BIRTHDATE_FORMAT_UK_AU);

export const validAge = (value, allValues, props) => {
  const { isUKAUNZ = getIsFormCountryUKAUNZ(props) } = props;
  const { bypassAgeRestriction } = props;
  if (bypassAgeRestriction) {
    return VALID;
  }
  if (
    value &&
    moment(allValues.embarkDate, 'YYYY-MM-DD').diff(
      moment(value, isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA),
      'years'
    ) < 18
  ) {
    return INVALID_DATE_AGE;
  }
  return VALID;
};

export const validGifEmail = (value) => {
  if (!value) {
    return VALID;
  }
  return email(value);
};

export const validGifEmailLength = lengthValidator({
  noValue: VALID,
  error: INVALID_EMAIL_LENGTH,
  max: 64,
});

export const validAddressLine1 = validAddressLineCheck(INVALID_ADDRESS_LINE_1);

export const validAddressLine2 = validAddressLineCheck(INVALID_ADDRESS_LINE_2);

export const validGifCity = lengthValidator({
  noValue: VALID,
  error: INVALID_CITY_LENGTH,
  min: 2,
  max: 20,
});

export const validGifState = (value, allValues) => {
  if (!value) {
    const country = allValues?.country || '';
    switch (country) {
      case COUNTRIES.AUSTRALIA:
        return STATE_REQUIRED_AU;
      case COUNTRIES.CANADA:
        return STATE_REQUIRED_CA;
      case COUNTRIES.NEW_ZEALAND:
        return STATE_REQUIRED_NZ;
      case COUNTRIES.UNITED_KINGDOM:
      case COUNTRIES.UNITED_KINGDOM_GB:
        return STATE_REQUIRED_UK;
      case COUNTRIES.UNITED_STATES:
        return STATE_REQUIRED_US;
      default:
        return STATE_REQUIRED;
    }
  }
  return VALID;
};

export const validGifEmergencyState = (value, allValues) => {
  if (!value) {
    const country = allValues?.emergencyContactCountryCode || '';
    switch (country) {
      case COUNTRIES.AUSTRALIA:
        return STATE_REQUIRED_AU;
      case COUNTRIES.CANADA:
        return STATE_REQUIRED_CA;
      case COUNTRIES.NEW_ZEALAND:
        return STATE_REQUIRED_NZ;
      case COUNTRIES.UNITED_KINGDOM:
      case COUNTRIES.UNITED_KINGDOM_GB:
        return STATE_REQUIRED_UK;
      case COUNTRIES.UNITED_STATES:
        return STATE_REQUIRED_US;
      default:
        return STATE_REQUIRED;
    }
  }
  return VALID;
};

export const validEmergencyContactName = (value) => {
  if (!value) {
    return VALID;
  }
  return value.match(/^[ '\-A-Z]{2,30}$/i) ? VALID : INVALID_EMERGENCY_NAME_FORMAT;
};

export const validEmergencyAddressLine1 = validAddressLineCheck(INVALID_EMERGENCY_ADDRESS_LINE_1);

export const validEmergencyAddressLine2 = validAddressLineCheck(INVALID_EMERGENCY_ADDRESS_LINE_2);

export const validEmergencyContactRelation = lengthValidator({
  noValue: VALID,
  error: INVALID_EMERGENCY_RELATION_FORMAT,
  min: 2,
  max: 20,
});

export const phoneNumberValidation = (value, allValues, props, name) => {
  let country = allValues.emergencyContactCountryCode;
  if (['phone', 'mobile'].includes(name)) {
    ({ country } = allValues);
  }

  const phoneNumberInvalidErrors = {
    US: INVALID_PHONE_NUMBER_US_CA,
    CA: INVALID_PHONE_NUMBER_US_CA,
    UK: INVALID_PHONE_NUMBER_UK_AU,
    GB: INVALID_PHONE_NUMBER_UK_AU,
    AU: INVALID_PHONE_NUMBER_UK_AU,
    NZ: INVALID_PHONE_NUMBER,
  };

  const mobileNumberInvalidErrors = {
    US: INVALID_MOBILE_NUMBER_US_CA,
    CA: INVALID_MOBILE_NUMBER_US_CA,
    UK: INVALID_MOBILE_NUMBER_UK_AU,
    GB: INVALID_MOBILE_NUMBER_UK_AU,
    AU: INVALID_MOBILE_NUMBER_UK_AU,
    NZ: INVALID_MOBILE_NUMBER,
  };

  const phoneNumberErrCode = phoneNumberInvalidErrors[country] || INVALID_PHONE_NUMBER;
  const mobileNumberErrCode = mobileNumberInvalidErrors[country] || INVALID_MOBILE_NUMBER;
  const errCode = name === 'mobile' ? mobileNumberErrCode : phoneNumberErrCode;

  const [max, min] = value?.[0] === '+' ? [16, 8] : [15, 7];
  switch (country) {
    case COUNTRIES.AUSTRALIA:
    case COUNTRIES.UNITED_KINGDOM:
    case COUNTRIES.UNITED_KINGDOM_GB:
      return lengthValidator({
        noValue: VALID,
        error: errCode,
        min,
        max,
      })(value);
    case COUNTRIES.UNITED_STATES:
    case COUNTRIES.CANADA:
      return lengthValidator({
        noValue: VALID,
        error: errCode,
        min: 10,
        max: 10,
      })(value);
    default:
      return lengthValidator({
        noValue: VALID,
        error: errCode,
        min: 2,
        max: 20,
      })(value);
  }
};

export const amountDueValidation = (value, allValues, props) => {
  if (value < props.minDueAmount) {
    return INVALID_MINIMUM_PAYMENT;
  }
  if (value === 0) {
    return MINIMUM_PAYMENT_CANNOT_BE_ZERO;
  }
  if (value > props.totalDueAmount) {
    return INVALID_MAXIMUM_PAYMENT;
  }
  return VALID;
};

export const validGifPostalCode = (value, allValues, props, name) => {
  const postalCode = validPostalCode(value, allValues, props, name);

  if (postalCode !== VALID) {
    return postalCode;
  }
  return lengthValidator({
    noValue: VALID,
    error: INVALID_ZIP_LENGTH,
    min: 2,
    max: 10,
  })(value);
};

export const validPassportIssueDate = (value) => {
  if (!moment(value, DEFAULT_DATE_FORMAT.NA).isBefore(moment())) {
    return INVALID_PASSPORT_ISSUED;
  }
  return dateValidator(DEFAULT_DATE_FORMAT.NA, INVALID_PASSPORT_ISSUED)(value);
};
export const validPassportIssueDateUKAU = (value) => {
  if (!moment(value, DEFAULT_DATE_FORMAT.EU).isBefore(moment())) {
    return INVALID_PASSPORT_ISSUED_UK_AU;
  }
  return dateValidator(DEFAULT_DATE_FORMAT.EU, INVALID_PASSPORT_ISSUED_UK_AU)(value);
};

export const validPassportExpirationDate = dateValidator(DEFAULT_DATE_FORMAT.NA, INVALID_PASSPORT_EXPIRES);
export const validPassportExpirationDateUKAU = dateValidator(DEFAULT_DATE_FORMAT.EU, INVALID_PASSPORT_EXPIRES_UK_AU);

export const validPassportWillExpire = (value, allValues) => {
  if (!value) {
    return VALID;
  }
  const { disembarkDate } = allValues;
  const expires = moment(value, DEFAULT_DATE_FORMAT.NA).isSameOrBefore(
    moment(disembarkDate, DEFAULT_DATE_FORMAT.YEAR_FIRST)
  );
  return expires ? INVALID_PASSPORT_WILL_EXPIRE : VALID;
};

export const validPassportWillExpireUKAU = (value, allValues) => {
  if (!value) {
    return VALID;
  }
  const { disembarkDate } = allValues;
  const expires = moment(value, DEFAULT_DATE_FORMAT.EU).isSameOrBefore(
    moment(disembarkDate, DEFAULT_DATE_FORMAT.YEAR_FIRST)
  );
  return expires ? INVALID_PASSPORT_WILL_EXPIRE : VALID;
};

export const passportWillExpireSoon = (value) => {
  if (!value || value.length !== 8) {
    return null;
  }
  if (moment(value, DEFAULT_DATE_FORMAT.NA).isBefore(moment().add(180, 'days'))) {
    return WARNING_PASSPORT_EXPIRES_SOON;
  }
  return null;
};

export const passportWillExpireSoonUKAU = (value) => {
  if (!value || value.length !== 8) {
    return null;
  }
  if (moment(value, DEFAULT_DATE_FORMAT.EU).isBefore(moment().add(180, 'days'))) {
    return WARNING_PASSPORT_EXPIRES_SOON;
  }
  return null;
};

export const validPassportNumber = (value) => {
  if (value?.match(/[^A-Za-z0-9]/)) {
    return INVALID_PASSPORT_NUMBER;
  }

  return lengthValidator({
    noValue: VALID,
    error: INVALID_PASSPORT_NUMBER_LENGTH,
    max: 12,
  })(value);
};

export const validLicenseExpirationDate = dateValidator(DEFAULT_DATE_FORMAT.NA, INVALID_LICENSE_EXPIRES);

export const validLicenseWillExpire = (value, allValues, props) => {
  const { isUKAUNZ = getIsFormCountryUKAUNZ(allValues) } = props;
  if (!value) {
    return VALID;
  }
  const { disembarkDate } = allValues;
  const expires = moment(value, isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA).isBefore(
    moment(disembarkDate, DEFAULT_DATE_FORMAT.YEAR_FIRST)
  );
  return expires ? INVALID_LICENSE_WILL_EXPIRE : VALID;
};

export const licenseWillExpireSoon = (value, allValues, props) => {
  const { isUKAUNZ = getIsFormCountryUKAUNZ(allValues) } = props;
  if (!value || value.length !== 8) {
    return null;
  }
  if (
    !moment(value, isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA).isBefore(moment()) &&
    moment(value, isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA).isBefore(moment().add(180, 'days'))
  ) {
    return WARNING_LICENSE_EXPIRES_SOON;
  }
  return null;
};
export const licenseExpired = (value, allValues, props) => {
  const { isUKAUNZ = getIsFormCountryUKAUNZ(allValues) } = props;
  if (!value) {
    return VALID;
  }
  if (moment(value, isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA).isBefore(moment())) {
    return INVALID_LICENSE_EXPIRED;
  }
  return null;
};

export const validLicenseNumber = (value) => {
  if (value?.match(/[^A-Za-z0-9*]/)) {
    return INVALID_LICENSE_NUMBER;
  }

  return null;
};

export const validInsuranceProviderNameLength = lengthValidator({
  noValue: VALID,
  error: INVALID_INSURANCE_PROVIDER_LENGTH,
  max: 50,
});

export const validInsurancePolicyNumberLength = lengthValidator({
  noValue: VALID,
  error: INVALID_INSURANCE_POLICY_NUMBER_LENGTH,
  max: 20,
});

// Flight search validations
export const validAirport = (value, allValues, props, name) => {
  const noParenRegEx = /[A-Z]{3}/;
  if (!value || typeof value !== 'string') {
    return INVALID;
  }
  if (value.match(noParenRegEx)) {
    if (name.endsWith('.to')) {
      const matchingAirports = (airport1, airport2) => {
        if (airport1 && airport2) {
          return airport1.trim().toUpperCase() === airport2.trim().toUpperCase();
        }
        return false;
      };
      const hasInvalidAirports = (vals, currentIndex) => {
        if (vals.filter((row, ind) => ind === 0 && matchingAirports(row.to, row.from)).length > 0) {
          return true;
        }
        if (currentIndex === 0 && vals.length > 1) {
          if (matchingAirports(vals[0].to, vals[1].to)) {
            return true;
          }
        }
        return false;
      };
      const { postName, preName } = props;
      if (
        name.startsWith(preName) &&
        hasInvalidAirports(allValues[preName], name.startsWith(`${preName}[0]`) ? 0 : 1)
      ) {
        return INVALID;
      }
      if (
        name.startsWith(postName) &&
        hasInvalidAirports(allValues[postName], name.startsWith(`${postName}[0]`) ? 0 : 1)
      ) {
        return INVALID;
      }
    }
    return VALID;
  }
  return INVALID;
};

export const validFlightDate = (value, allValues, props, name) => {
  const { postName, preName } = props;
  const compareDates = (arr) => {
    return moment(arr[1].date).diff(moment(arr[0].date), 'days') < 1;
  };

  if (moment().isAfter(value)) {
    return INVALID;
  }

  if (allValues[preName].length > 1 && name.includes(`${preName}[0]`)) {
    const flightArray = allValues[preName];
    if (compareDates(flightArray)) {
      return INVALID_DATES;
    }
  }
  if (allValues[postName].length > 1 && name.includes(`${postName}[1]`)) {
    const flightArray = allValues[postName];
    if (compareDates(flightArray)) {
      return INVALID_DATES;
    }
  }

  if (name === `preFlightSelector[${allValues[preName].length - 1}].date`) {
    if (moment(value).diff(moment(props.arriveOnMaxDate), 'days') > 0) {
      return ERROR_CODES.INVALID_DEVIATION_DATE;
    }
  }

  if (name === 'postFlightSelector[0].date') {
    if (moment(value).diff(moment(props.departOnMinDate), 'days') < 0) {
      return ERROR_CODES.INVALID_DEVIATION_DATE;
    }
  }
  return VALID;
};

export const validateFrequentFlyer = (value, allValues, props, name) => {
  let frequentFlyerNumberPath = '';
  let airlineCodePath = '';
  if (name.includes('airlineCode')) {
    airlineCodePath = name;
    frequentFlyerNumberPath = name.replace('airlineCode', 'frequentFlyerNumber');
  } else {
    frequentFlyerNumberPath = name;
    airlineCodePath = name.replace('frequentFlyerNumber', 'airlineCode');
  }

  if (name.includes('airlineCode') || name.includes('frequentFlyerNumber')) {
    const airlineCode = get(allValues, airlineCodePath, '');
    const frequentFlyerNumber = get(allValues, frequentFlyerNumberPath, '');
    const paxFFNRegex = /^passengers\[(\d+)\].frequentFlyers\[(\d+)\].*/i;
    const paxFFNMatch = name.match(paxFFNRegex);
    if (paxFFNMatch && paxFFNMatch.length === 3) {
      const paxIdx = paxFFNMatch[1];
      const ffIdx = paxFFNMatch[2];
      if (airlineCode && name.includes('airlineCode')) {
        const duplicates = get(allValues, `passengers[${paxIdx}].frequentFlyers`, []).filter(
          (f, i) => safeStringCompare(f.airlineCode, airlineCode) && !safeStringCompare(i.toString(), ffIdx)
        );
        if (duplicates && duplicates.length > 0) {
          return INVALID_DUPLICATE_AIRLINE;
        }
      }

      if (frequentFlyerNumber && airlineCode && name.includes('frequentFlyerNumber')) {
        const passengers = get(allValues, 'passengers', []);
        if (passengers && passengers.length > 1) {
          const otherPassenger = passengers[paxIdx === '0' ? 1 : 0];
          const duplicates =
            otherPassenger &&
            otherPassenger.frequentFlyers &&
            otherPassenger.frequentFlyers.filter(
              (f) =>
                f &&
                safeStringCompare(f.airlineCode, airlineCode) &&
                safeStringCompare(f.frequentFlyerNumber, frequentFlyerNumber)
            );
          if (duplicates && duplicates.length > 0) {
            return INVALID_DUPLICATE_FFNUMBER;
          }
        }
      }
    }
    if (((!airlineCode || airlineCode === -1) && !frequentFlyerNumber) || (!!airlineCode && !!frequentFlyerNumber)) {
      return VALID;
    }
    if (frequentFlyerNumber === '' && airlineCode !== '' && name.includes('frequentFlyerNumber')) {
      return INVALID;
    }
    if (airlineCode === '' && frequentFlyerNumber !== '' && name.includes('airlineCode')) {
      return INVALID;
    }
  }

  return VALID;
};

// Pre-Auth validations
export const passwordInvalid = lengthValidator({
  noValue: VALID,
  error: PASSWORD_INVALID,
  min: 8,
  max: 64,
});

// eslint-disable-next-line
export const matchingPasswords = (value = '', allValues = {}) =>
  value === allValues.password ? VALID : PASSWORD_MISMATCH;

// Health Questionnaire validations
export const vaccinationDate = (value, allValues, { isUKAUNZ }) => {
  if (!value) {
    return VALID;
  }

  const format = isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA;
  const date = moment(value, format);

  if (!date.isValid() || value.length < 8) {
    return INVALID_DATE;
  }
  if (date.isAfter(moment())) {
    return INVALID_FUTURE_VACCINATION;
  }

  if (date.isBefore('2020-01-01')) {
    return INVALID_PAST_VACCINATION;
  }
  return VALID;
};
