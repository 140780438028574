import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { appInsightsHelper } from '@viking-eng/telemetry';
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { createSelector } from 'reselect';
import commonStore, { modalStore, userStore } from '../../common';
import { getData } from '../../common/Api';
import {
  ADDITIONS_TYPE,
  APP_INSIGHTS_TRACK_TYPE,
  APP_PATHS,
  PAGE_NAMES,
  REGIONAL_LONG_DATES,
  REGIONAL_SHORT_DATES,
  TAB_PATHS,
  THREE_BY_TWO,
  TWO_BY_ONE,
} from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import {
  buildUrl,
  convertStringToStartCase,
  decodeCountryCodeFromCurrency,
  getCarouselImageArray,
  getImageAttributes,
  getPassengerAbbrevName,
  mapCardSections,
  navigateTo,
  replaceCMSTokenWithValue,
} from '../../common/Utils';

const { logger } = appInsightsHelper;
const { getBookingDetails, getDaysToGo, getItinerary } = userStore.selectors;
const { getLabels, getPageTabLabels, getPassengerTicketContract, getIsUKAUNZ, getMvjFlags } = commonStore.selectors;
const { getModalData } = modalStore.selectors;

const formatDates = (startDate, endDate, shortDates) => {
  const longFormat = `${shortDates}, Y`;

  const dates = [startDate, endDate].map((date) => moment(date));

  const embarkFormat = dates[0].isSame(dates[1], 'year') ? shortDates : longFormat;
  const formats = [embarkFormat, longFormat];

  return dates.map((date, index) => date.format(formats[index])).join(' \u{2013} ');
};

const getGuestsInfo = (guests, comboBookings) => {
  const { PRE, POST } = ADDITIONS_TYPE;
  const guestsExtensions = {
    [PRE]: [],
    [POST]: [],
  };
  const guestNames = [];

  guests.forEach(({ extensions = [], ...passenger }) => {
    const guestName = getPassengerAbbrevName(passenger);
    guestNames.push(guestName);
    extensions.forEach(({ city, type, fromDate, parentCode }) => {
      if ([PRE, POST].includes(type)) {
        let withLink = !parentCode;
        if (withLink && !!comboBookings.length) {
          withLink =
            type === PRE
              ? moment(fromDate).isBefore(moment(comboBookings[0].embarkDate))
              : moment(fromDate).isAfter(moment(comboBookings[comboBookings.length - 1].embarkDate));
        }
        guestsExtensions[type].push({
          value: `${guestName} \u{2013} ${city}`,
        });
      }
    });
  });

  return {
    extensions: guestsExtensions,
    guests: guestNames,
  };
};

const getShipsInfo = (shipInfo) => {
  const shipNames = shipInfo.map(({ shipName }) => convertStringToStartCase(shipName));
  return {
    shipInfo: shipNames,
  };
};

const getStateroomsInfo = (stateroomInfo) => {
  if (!stateroomInfo) {
    return null;
  }

  const info = stateroomInfo.reduce(
    (acc, { selectedStateroom, stateroomNumber, wheelchairAccess }) => {
      // Sometimes CMS data isn't loaded and selectedStateroom will come back as NULL
      const title = `${selectedStateroom?.header || ''} #${stateroomNumber}`.trim();
      const isGTY = stateroomNumber === 'GTY';
      const isADA = wheelchairAccess === 'Y' || false;
      acc.titles.push(isGTY ? '' : title);
      acc.isGTY.push(isGTY);
      acc.isADA.push(isADA);
      return acc;
    },
    {
      titles: [],
      isGTY: [],
      isADA: [],
    }
  );
  return info;
};

const getDisplayStateroom = (originalStateroom, category) => originalStateroom.replace(/\(.*\)/, `(${category})`);

const travelBookingStore = createPageTitleDuck('travelBooking').extend({
  selectors: {
    getTravelBookingSections: (state) => get(state, 'travelBooking.content.sections', []),
    getMapImage: (state) => {
      const mapImages = get(state, 'common.content.mapImages', []);
      return [mapImages];
    },
    getTravelBookingHeadings: new Selector(({ getTravelBookingSections }) =>
      createSelector(
        [(state) => getPageTabLabels(state)(PAGE_NAMES.BOOKING_SUMMARY), getTravelBookingSections],
        ({ labels }, sections) => {
          const headings = labels;
          sections
            .filter(({ reference }) => reference)
            .forEach(({ reference, title }) => {
              if (!headings[reference]) {
                headings[reference] = title;
              }
            });
          return headings;
        }
      )
    ),
    getAirDepartureAndArrivalLabels: new Selector(({ getTravelBookingHeadings }) =>
      createSelector(
        [getTravelBookingHeadings, getBookingDetails, getIsUKAUNZ],
        (headings, bookingDetails, isUKAUNZ) => {
          if (!bookingDetails) {
            return {};
          }
          const { airItineraryPendingDays, passengers, voyage: { startDate } = {}, airViewership } = bookingDetails;
          let vikingAirIsBooked = '';
          const sortBySegmentId = (a, b) => a.segmentId > b.segmentId;
          // TODO clean up after core deploy to have air pre and post objects
          // with segment prop instead of array
          const sortedAir =
            passengers &&
            passengers.map(({ air = {} }) => {
              const pre = get(air, 'pre.segments', air.pre) || [];
              const post = get(air, 'post.segments', air.post) || [];
              const sortedPre = pre.sort(sortBySegmentId);
              const sortedPost = post.sort(sortBySegmentId);
              const { departureAirportCode: preDeparture } = pre[0] || {};
              const { departureAirportCode: postDeparture } = post.slice(-1)[0] || {};
              vikingAirIsBooked = vikingAirIsBooked || preDeparture || postDeparture;
              return {
                pre: sortedPre,
                post: sortedPost,
              };
            });
          // Return an empty object if viking air is not avaliable.
          if (!vikingAirIsBooked) {
            return {};
          }
          const headingsLength = Object.keys(headings).length;
          const airDeparture = [];
          const airReturn = [];
          let preCruiseInfo;
          let postCruiseInfo;
          let itineraryAvailableTimeFromStartOfCruise;
          if (startDate) {
            itineraryAvailableTimeFromStartOfCruise = moment(startDate)
              .subtract(airItineraryPendingDays, 'days')
              .format(isUKAUNZ ? REGIONAL_LONG_DATES.EU : REGIONAL_LONG_DATES.NA);
          }
          const airLink = airViewership ? APP_PATHS.AIR : '';
          if (airViewership) {
            passengers.forEach((passenger) => {
              const firstAndLastName = `${convertStringToStartCase(
                `${passenger.firstName} ${passenger.lastName}`,
                true
              )}`;

              const air = sortedAir[passenger.passengerNumber - 1];
              if (air.pre) {
                const cruiseOnly = air.pre.some((segment) => segment.departureAirportCode === 'C/O');
                if (!cruiseOnly) {
                  const preAirData = air.pre[0];
                  if (preAirData) {
                    preCruiseInfo = `${firstAndLastName}\u00A0${preAirData.departureAirportCode}\u2014${preAirData.arrivalAirportCode}`;
                    airDeparture.push(preCruiseInfo);
                  }
                }
              }

              if (air.post) {
                const cruiseOnly = air.post.some((segment) => segment.departureAirportCode === 'C/O');
                if (!cruiseOnly) {
                  const postAirData = air.post.slice(-1)[0];
                  if (postAirData) {
                    postCruiseInfo = `${firstAndLastName}\u00A0${postAirData.departureAirportCode}\u2014${postAirData.arrivalAirportCode}`;
                    airReturn.push(postCruiseInfo);
                  }
                }
              }
            });
          } else {
            let itineraryNotAvaliable;
            if (headingsLength > 0) {
              itineraryNotAvaliable = replaceCMSTokenWithValue(headings['Air-Available-By-label'], [
                { key: 'AIR_AVAILABLE_DATE', value: itineraryAvailableTimeFromStartOfCruise },
              ]);
            }
            airDeparture.push(itineraryNotAvaliable);
            airReturn.push(itineraryNotAvaliable);
          }
          return {
            airDeparture,
            airReturn,
            airLink,
          };
        }
      )
    ),
    getCruiseCities: new Selector(() =>
      createSelector(
        [getItinerary, (state) => getPageTabLabels(state)(PAGE_NAMES.BOOKING_SUMMARY), getMvjFlags],
        (itinerary, { labels }, mvjFlags) => {
          if (!itinerary.length || !labels) {
            return null;
          }
          const cities = [...itinerary];
          cities.splice(1, cities.length - 2);
          const [from, to] = cities.map(({ cityName, localityName, countryName }) => {
            if (localityName) {
              return `${cityName}, ${localityName}`;
            } else if (countryName) {
              return `${cityName}, ${countryName}`;
            }
            return cityName;
          });
          const [fromCountry] = cities.map(({ countryName }) => countryName);

          let fromKey = 'FROM';
          let fromValue = from;
          let toKey = 'TO';
          let toValue = to;
          let labelValue = labels.itineraryValue;

          if (from === to && mvjFlags?.showRoundtripCruises) {
            fromKey = 'FROM_CITY';
            fromValue = from;
            toKey = 'FROM_COUNTRY';
            toValue = fromCountry;
            labelValue = labels.itineraryRoundtripValue;
          }
          return replaceCMSTokenWithValue(labelValue, [
            { key: fromKey, value: fromValue },
            { key: toKey, value: toValue },
          ]);
        }
      )
    ),
    getTravelBookingDetails: new Selector((selectors) => {
      const { getTravelBookingHeadings, getAirDepartureAndArrivalLabels, getCruiseCities } = selectors;
      return createSelector(
        [
          getTravelBookingHeadings,
          getBookingDetails,
          getAirDepartureAndArrivalLabels,
          getCruiseCities,
          getLabels,
          getIsUKAUNZ,
          getMvjFlags,
        ],
        (headings, bookingDetails, airTravel, cruiseCities, label, isUKAUNZ, mvjFlags) => {
          if (isEmpty(bookingDetails)) {
            return [];
          }
          const {
            bookingNumber,
            passengers,
            ship,
            comboBookings,
            voyage: { endDate, startDate },
          } = bookingDetails;

          const {
            extensions: { pre, post },
            guests,
          } = getGuestsInfo(passengers, comboBookings);

          const shipData = comboBookings.length ? comboBookings : [ship];
          const { shipInfo } = getShipsInfo(shipData);

          const stateroomInfo = getStateroomsInfo(shipData);
          if (!stateroomInfo) {
            logger({
              type: APP_INSIGHTS_TRACK_TYPE.TRACE,
              name: 'Stateroom Error',
              message: 'Error fetching stateroom info',
              severity: SeverityLevel.Warning,
            });
            navigateTo(APP_PATHS.OOPS_PAGE);
            return [];
          }
          const { titles, isGTY, isADA } = stateroomInfo;
          const headingsLength = Object.keys(headings).length;

          const stateroomDerivedAttributes = isGTY.map((GTY) => (GTY ? '' : {}));

          let stateroomTokenPending = '';
          if (headingsLength > 0) {
            stateroomTokenPending = get(label, 'pages.bookingSummary.labels.StateroomPending', '');
          }

          const stateroomValue = titles.map((title, index) => {
            if (!title) {
              return stateroomTokenPending;
            }
            return getDisplayStateroom(title, shipData[index].stateroomCategory);
          });
          const isPending = isGTY[0];
          const isADAAccessible = mvjFlags?.enableAda ? isADA?.[0] : false;
          const getHeading = (reference) => ({
            heading: headings[reference],
            reference,
          });
          return [
            {
              ...getHeading('bookingNumber'),
              value: bookingNumber,
            },
            {
              ...getHeading('cruiseDate'),
              value: formatDates(startDate, endDate, isUKAUNZ ? REGIONAL_SHORT_DATES.EU : REGIONAL_SHORT_DATES.NA),
            },
            {
              ...getHeading('guests'),
              value: guests,
            },
            {
              ...getHeading('itinerary'),
              value: cruiseCities,
              link: APP_PATHS.ITINERARY,
            },
            {
              ...getHeading('airDeparture'),
              value: airTravel.airDeparture,
              link: airTravel.airLink,
            },
            {
              heading: headings.airReturn,
              value: airTravel.airReturn,
              link: airTravel.airLink,
            },
            {
              ...getHeading('preTripExtension'),
              value: pre,
            },
            {
              ...getHeading('postTripExtension'),
              value: post,
            },
            {
              ...getHeading('ship'),
              value: shipInfo,
              link: `${APP_PATHS.ONBOARD_EXPERIENCE}/${TAB_PATHS.onboardExperience.shipCrew}`,
            },
            {
              ...getHeading('stateroom'),
              value: stateroomValue,
              attributes: stateroomDerivedAttributes,
              isPending,
              link: `${APP_PATHS.ONBOARD_EXPERIENCE}/${TAB_PATHS.onboardExperience.stateroom}`,
              isADAAccessible,
            },
          ];
        }
      );
    }),
    getBookingDetailsContent: new Selector(({ getTravelBookingDetails, getTravelProtectionModalData, getMapImage }) =>
      createSelector(
        [
          (state) => getPageTabLabels(state)(PAGE_NAMES.BOOKING_SUMMARY),
          getBookingDetails,
          getTravelBookingDetails,
          getTravelProtectionModalData,
          getDaysToGo,
          getMapImage,
        ],
        ({ labels, buttons }, bookingDetails, details, travelProtectionModalData, daysToGo, mapImage) => {
          if (!bookingDetails || !bookingDetails.cruise) {
            return {};
          }
          const { cruise: { name, title: cruiseTitle } = {} } = bookingDetails;

          const mappedImages = mapImage.map((image) =>
            image.map((img) => ({
              ...img,
              mediaUrl: img.url,
            }))
          );
          const carouselImages = getCarouselImageArray({
            images: mappedImages,
            imageRatio: [THREE_BY_TWO],
          });
          if (carouselImages.length === 0) {
            carouselImages.push({
              mediaUrl: '',
              type: THREE_BY_TWO,
              ratio: THREE_BY_TWO,
            });
          }
          let thumbnailMap;
          thumbnailMap = mappedImages[0] ? mappedImages[0].find(({ type }) => type === THREE_BY_TWO) : null;

          if (thumbnailMap) {
            thumbnailMap = mappedImages[0].map(() => {
              const { alt, url } = thumbnailMap;
              return {
                alt,
                src: url,
              };
            });
          } else {
            thumbnailMap = [
              {
                alt: '',
                src: '',
                type: THREE_BY_TWO,
              },
            ];
          }
          return {
            cruiseName: name,
            daysToGo,
            daysToGoLabel: get(labels, `daysToGo.${daysToGo > 1 ? 'plural' : 'singular'}`),
            details,
            images: thumbnailMap,
            pictureModalData: {
              printLabel: buttons.print,
              title: cruiseTitle || name,
              images: carouselImages.map((image) => getImageAttributes({ image })),
            },
            travelProtectionModalData,
          };
        }
      )
    ),
    getTravelBookingCardData: new Selector(({ getTravelBookingSections }) =>
      createSelector(getTravelBookingSections, (sections) =>
        mapCardSections(sections.filter(({ cards }) => cards.length > 0))
      )
    ),
    getTravelProtectionModalData: new Selector(({ getTravelBookingCardData }) =>
      createSelector(
        [getTravelBookingCardData, getLabels, getModalData],
        (travelBookingCardData = [], labels, { id: modalId }) => {
          const cardSections = travelBookingCardData.find((cardSection) => cardSection.cards);
          const travelProtectionModalData =
            cardSections && cardSections.cards.find((card) => card.reference === 'travelProtection');
          const travelProtectionTripMateLabel = /^travelProtectionTripMateLabel/g;
          const printLabel = get(labels, 'print', '');
          const travelProtectionModalDataAvailable =
            travelProtectionModalData && Object.keys(travelProtectionModalData).length > 0;

          if (travelProtectionModalDataAvailable) {
            const {
              images,
              modal: { sections },
              title,
            } = travelProtectionModalData;

            const { longText: regionalBookingPhoneText } =
              sections.find((section) => section.reference && section.reference.match('travelProtectionPhoneNumber')) ||
              {};
            const { longText: phoneNumber, reference: phoneReference } =
              sections.find((section) => section.reference && section.reference.match('PHONE')) || {};

            const sectionContent = sections.find((section) => section.reference && section.reference === 'description');

            const { subtitle: planDetailsLink } = sections.find(
              (section) => section.reference && section.reference.match(travelProtectionTripMateLabel)
            );

            /*
            Thanks to the mapCardSections Utils function that getTravelBookingCardData depends on
            to map the card data, this remapping of the image data needs to take place.
          */
            const newImages = images
              .map((image) => ({
                ...image,
                mediaUrl: image.src,
              }))
              .map((image) => getImageAttributes({ image }));

            return {
              id: modalId,
              images: newImages.filter((img) => img.type === TWO_BY_ONE),
              printLabel,
              sections: [sectionContent],
              sideContentData: {
                regionalBookingPhoneNumber: replaceCMSTokenWithValue(regionalBookingPhoneText, [
                  { key: phoneReference, value: phoneNumber },
                ]),
                planDetailsLink,
              },
              title,
            };
          }

          return {};
        }
      )
    ),
  },
});

export const fetchTravelBookingPageContent = () => (dispatch, getState) => {
  const bookingDetails = getBookingDetails(getState());
  const { receiveContent } = travelBookingStore.creators;
  const country = decodeCountryCodeFromCurrency(bookingDetails.currency);

  const url = buildUrl('/bookingDetails', ['voyage.type'], bookingDetails, { country });
  dispatch(
    getData({
      url,
      store: travelBookingStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export const handleContractOpen = () => (dispatch, getState) => {
  const ptcData = getPassengerTicketContract(getState());
  const pdfUrl = ptcData.callToActionUrl;
  window.open(pdfUrl, '_blank');
};

export default travelBookingStore;
