import _isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';
import { change, formValueSelector, reset } from 'redux-form';
import commonStore from '../../../common';
import { ALERT_TYPES, COUNTRIES, COUNTRIES_NATIONALITY, FORMS } from '../../../common/Constants';
import { setViewAndShowModal } from '../../../common/ModalStore';
import userStore from '../../../common/UserStore';
import documentsStore from '../../../pages/documents/DocumentsStore';
import GuestInfoForm from './GuestInfoForm';

const {
  getErrors,
  getGenders,
  getPopularCountries,
  getTitles,
  getIsUKAUNZ,
  getFeatureRestrictedMessage,
} = commonStore.selectors;

const { getCountryCodeFromCurrency, getIsMississippi } = userStore.selectors;

const {
  selectors: {
    getAddressValidationError,
    getPhoneMasks,
    getPostalCodeMasks,
    getGifErrors,
    getGifCountriesSelected,
    getGifFormPlaceholders,
    getFormActionLabels,
    getRegionalGifFields,
    getCountryOptions,
    getStatesByCountry,
  },
  creators: { updateNextLocation },
} = documentsStore;

const formName = FORMS.GUEST_INFO_FORM;

const mapStateToProps = (state, { asyncValidating, pristine, submitting, submitSucceeded, submitFailed, valid }) => {
  const errors = getErrors(state);
  const formActionLabels = getFormActionLabels(state);
  const { guestErrors, emergencyContactErrors } = getGifErrors(state);
  const addressValidationError = getAddressValidationError(state);

  const alert = {};
  if (!(submitting || asyncValidating)) {
    if (pristine && submitSucceeded) {
      alert.type = ALERT_TYPES.INFO;
      alert.message = formActionLabels.alert;
    }
    if (submitFailed) {
      alert.type = ALERT_TYPES.ERROR;
      alert.message = errors.GifFieldValidationError;
      if (valid) {
        alert.message = errors.GifUpdateFailed;
      }
      if (addressValidationError?.errorData?.advisoryCode === '51193') {
        alert.message = addressValidationError.errorData?.errorDescription;
      }
    }
  }

  const { states, emergencyStates } = getStatesByCountry(state);

  return {
    popularCountries: getPopularCountries(state),
    titles: getTitles(state),
    genders: getGenders(state),
    getCountryOptions: getCountryOptions(state),
    states,
    emergencyStates,
    passengerID: formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'passengerID'),
    errors,
    phoneMasks: getPhoneMasks(state),
    zipMasks: getPostalCodeMasks(state),
    guestErrors: _isEmpty(guestErrors) ? errors : guestErrors,
    emergencyContactErrors: _isEmpty(emergencyContactErrors) ? errors : emergencyContactErrors,
    selectedCountries: getGifCountriesSelected(state),
    mainCountries: Object.values(COUNTRIES),
    placeholders: getGifFormPlaceholders(state),
    formActionLabels,
    regionalLabels: getRegionalGifFields(state),
    alert,
    isSailingSoonLockdown: !!getFeatureRestrictedMessage(state),
    isUKAUNZ: getIsUKAUNZ(state),
    isMississippi: getIsMississippi(state),
    isUSGuest:
      getCountryCodeFromCurrency(state) === COUNTRIES.UNITED_STATES &&
      formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'nationality') === COUNTRIES_NATIONALITY.UNITED_STATES,
    isPhoneRequired: !formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'mobile'),
    isPassportRequired:
      !formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'licenseNumber') ||
      !formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'stateLicense') ||
      !formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'expiresOnLicense'),
    guestInfoCountry: formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'country'),
    emergencyContactCountry: formValueSelector(FORMS.GUEST_INFO_FORM)(state, 'emergencyContactCountryCode'),
  };
};

const mapDispatchToProps = (dispatch) => ({
  resetForm: () => dispatch(reset(formName)),
  resetFields: (...fields) => fields.forEach((field) => dispatch(change(formName, field, ''))),
  handleNextLocation: (pathname) => dispatch(updateNextLocation(pathname)),
  showModal: (modalId) => dispatch(setViewAndShowModal(modalId)),
  autoSelectState: (fieldName, selectedState) => {
    dispatch((_, getState) => {
      if (!formValueSelector(FORMS.GUEST_INFO_FORM)(getState(), fieldName)) {
        dispatch(change(formName, fieldName, selectedState));
      }
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(GuestInfoForm);
