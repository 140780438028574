import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import { getData } from '../../common/Api';
import {
  BOOKING_PACKAGE_TYPE,
  COUNTRIES,
  FEATURE_RESTRICTED,
  MVJ_FLAG_VARIABLES,
  ONE_BY_ONE,
  PAGE_NAMES,
  RESERVATION_STATE_KEYS,
  SERVICE_CODES,
  TWO_BY_ONE,
  VOYAGE_TYPE,
} from '../../common/Constants';
import commonStore, { modalStore, userStore } from '../../common/index';
import { createPageTabsDuck } from '../../common/ReusableDucks';
import {
  buildUrl,
  formatMoney,
  getCarouselImageArray,
  getCmsLabel,
  getSimpleReservationModalState,
  mapModalSections,
  replaceCMSTokenWithValue,
} from '../../common/Utils';

const baseBeforeYouGoStore = createPageTabsDuck('beforeYouGo');

const {
  getPassengerNames,
  getBookingDetails,
  getCountryCodeFromCurrency,
  isAllGifCompleted,
  getIsApprovedForPurchases,
  isGifOverride,
  getFeatureRestricted,
  getVoyageType,
  getIsDirect,
  getIsBalancePastDue,
  getIsMississippi,
} = userStore.selectors;

const {
  selectors: {
    getFlagValue,
    getLabels,
    getReservationModalState,
    getPageTabLabels,
    getViewOnlyContent,
    getIsPpgIncluded,
    getDisplayComfortCheckIn,
    getMvjFlags,
    getPaymentsAllEnabled,
    getPaymentsCheckoutEnabled,
  },
  types: { CART_UPDATED },
} = commonStore;
const { getModalData } = modalStore.selectors;
const { BEFORE_YOU_GO } = PAGE_NAMES;
const { PPG } = SERVICE_CODES;

const beforeYouGoStore = baseBeforeYouGoStore.extend({
  reducer: (state, action) => {
    switch (action.type) {
      case CART_UPDATED:
        return {
          ...state,
          content: {},
        };
      default:
        return state;
    }
  },
  selectors: {
    getBeforeYouGoModalCards: new Selector(({ getPageContent }) =>
      createSelector(
        [
          getPassengerNames,
          (state) => getPageTabLabels(state)(BEFORE_YOU_GO),
          getModalData,
          getReservationModalState,
          getCountryCodeFromCurrency,
          getPageContent,
          isAllGifCompleted,
          isGifOverride,
          getIsApprovedForPurchases,
          getFeatureRestricted,
          getViewOnlyContent,
          (state) => get(state, 'user.cart.isPPGInCart'),
          getIsDirect,
          getIsBalancePastDue,
          getIsPpgIncluded,
          getBookingDetails,
          getIsMississippi,
          (state) => getPaymentsAllEnabled(state),
          (state) => getPaymentsCheckoutEnabled(state),
        ],
        (
          passengerNames,
          labels,
          { id: modalId },
          reservationModalState,
          countryCode,
          { sections: beforeYouGoSections },
          allGifCompleted,
          gifOverride,
          isApprovedForPurchases,
          featureRestricted,
          { bannerMessage: viewOnlyMessage },
          isPPGInCart,
          isDirect,
          isBalancePastDue,
          isPpgIncluded,
          { comboBookings },
          isMississippi,
          isPaymentsAllEnabled,
          isPaymentsCheckoutEnabled
        ) => {
          const isCombo = comboBookings.length > 1;
          const defaultValue = {
            title: '',
          };
          if (!modalId) {
            return defaultValue;
          }
          const {
            buttons: {
              yesRemove,
              noRemove,
              addToCart,
              checkout,
              removeFromCart,
              requestRefund,
              refundYes,
              refundNo,
              close,
              print,
            },
            labels: { perPerson, packagePurchasedByStateroom },
          } = labels;
          const findCard = (element) => element.id === modalId;
          const findSection = (section) => section.cards.find(findCard);
          const cards = get(beforeYouGoSections.find(findSection), 'cards', null);

          if (!cards) {
            return defaultValue;
          }
          const {
            detailedDescription,
            details: { comboData, reservationStatus, singlePrice, extensionType, inventoryCode },
            images,
            modal: { title, subtitle, subTitle, states = {}, sections = [], callToActionTitle }, // CMSv2
            reference,
          } = cards.find(findCard);
          if (!title && !sections.length) {
            return defaultValue;
          }

          const isPpg = reference === PPG;
          const price = isPpg && comboData ? comboData.reduce((acc, leg) => acc + leg.singlePrice, 0) : singlePrice;
          const cost = formatMoney(price, 0, countryCode);
          const isTravelProtectionModal = reference === 'travelProtection';

          const priceLabel = !isTravelProtectionModal
            ? replaceCMSTokenWithValue(perPerson, [{ key: 'PRICE', value: cost }])
            : '';

          let { state, subText } = getSimpleReservationModalState(reservationStatus, priceLabel);
          let primaryButtonText;
          let secondaryButtonText;
          let modalAlertIsUrgent = true;
          let modalAlert = '';
          state = reservationModalState || state;
          const defaultLabels = {
            callToActionTitle,
          };
          const stateLabels = states[state] || defaultLabels;
          const { primaryButton = stateLabels, secondaryButton = {} } = stateLabels;
          const { CANCELED, CANCELING, OPEN, EDITING, RESERVED, EDIT, IN_CART } = RESERVATION_STATE_KEYS;
          primaryButtonText = addToCart;
          secondaryButtonText = secondaryButton.callToActionTitle;
          if (isPPGInCart) {
            modalAlert = getCmsLabel(sections, 'requiresPaymentCheckoutButton', 'subtitle');
            primaryButtonText = checkout;
          }

          if (isMississippi && isPpg) {
            const ppgModal = sections.find((section) => section.reference === 'ppgModal') || {};
            const mspPpgModal = sections.find((section) => section.reference === 'mspPpgModal') || {};
            ppgModal.longText = mspPpgModal.longText || ppgModal.longText || '';
          }

          switch (state) {
            case OPEN:
              modalAlert = isPpg ? packagePurchasedByStateroom : primaryButton.label || '';
              break;
            case EDIT:
              modalAlert = getCmsLabel(sections, 'requiresPaymentCheckoutButton', 'subtitle');
              primaryButtonText = removeFromCart;
              break;
            case CANCELED:
              subText = priceLabel;
              primaryButtonText = close;
              modalAlert = getCmsLabel(sections, 'cancellationSuccessfulCloseButton', 'subtitle');
              break;
            case EDITING:
              primaryButtonText = yesRemove;
              secondaryButtonText = noRemove;
              subText = null;
              break;
            case RESERVED:
              modalAlert = getCmsLabel(sections, 'purchaseConfirmedRequestRefundButton', 'title');
              subText = priceLabel;
              primaryButtonText = requestRefund;
              break;
            case CANCELING:
              subText = priceLabel;
              primaryButtonText = refundYes;
              secondaryButtonText = refundNo;
              modalAlert = '';
              break;
            default:
              break;
          }

          const gifIncompleteLockdown = isPpg && !allGifCompleted && !gifOverride && state === OPEN;
          let lockdownMessage;
          let isReservationAllowed = true;
          const lockedDownModal =
            featureRestricted || !isApprovedForPurchases || gifIncompleteLockdown || isBalancePastDue;
          if (featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING) {
            lockdownMessage = getCmsLabel(sections, 'bookingCustomizationsClosed', 'longText');
            isReservationAllowed = false;
          } else if (featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY) {
            lockdownMessage = viewOnlyMessage;
            isReservationAllowed = false;
          } else if (!isApprovedForPurchases) {
            lockdownMessage = isDirect
              ? getCmsLabel(sections, 'ppgInvalidStatus', 'title')
              : getCmsLabel(sections, 'invalidStatusTA', 'title');
            isReservationAllowed = false;
          } else if (isBalancePastDue) {
            lockdownMessage = isDirect
              ? getCmsLabel(sections, 'finalPaymentRequired', 'title')
              : getCmsLabel(sections, 'finalPaymentRequiredTA', 'title');
          }

          let sectionContent;
          if (isTravelProtectionModal) {
            sectionContent = [sections.find((section) => section.reference === 'description')];
          } else {
            sectionContent = mapModalSections(sections, true);
          }

          const mainContentTitle = isTravelProtectionModal ? sectionContent[0].title : title;

          let sideContentData = null;

          if (isTravelProtectionModal) {
            const travelProtectionTripMateLabel = /^travelProtectionTripMateLabel/g;
            const { longText: regionalBookingPhoneText } = sections.find(
              (section) => section.reference && section.reference.match('travelProtectionPhoneNumber')
            );
            const { longText: phoneNumber, reference: phoneReference } = sections.find(
              (section) => section.reference && section.reference.match('PHONE')
            );
            const { subtitle: planDetailsLink } = sections.find(
              (section) => section.reference && section.reference.match(travelProtectionTripMateLabel)
            );
            sideContentData = {
              regionalBookingPhoneNumber: replaceCMSTokenWithValue(regionalBookingPhoneText, [
                { key: phoneReference, value: phoneNumber },
              ]),
              planDetailsLink,
            };
          }

          if (isPpg && isPpgIncluded) {
            const { longText: regionalPhoneNumber } =
              sections.find((section) => section.reference && section.reference.match('PHONE')) || {};
            modalAlert = isCombo
              ? getCmsLabel(sections, 'PPGIncludedWithCSPhoneNumber', 'title')
              : getCmsLabel(sections, 'PPGIncludedWithCSPhoneNumber', 'subtitle');
            // Use REGION_PHONE so replaceCMSTokenWithValue() doesn't linkify the number
            modalAlert = modalAlert.replace('PHONE', 'REGION_PHONE');
            modalAlert = replaceCMSTokenWithValue(modalAlert, [{ key: 'REGION_PHONE', value: regionalPhoneNumber }]);
            modalAlertIsUrgent = false;
            primaryButtonText = null;
          }

          return {
            guests: passengerNames,
            id: modalId,
            title: mainContentTitle,
            alert: modalAlert.trim(),
            alertIsUrgent: modalAlertIsUrgent,
            printLabel: print,
            price: singlePrice,
            longDescription: detailedDescription,
            images: getCarouselImageArray({ images: [images], imageRatioPriorities: [TWO_BY_ONE] }),
            sections: sectionContent,
            reservationState: state,
            extensionType,
            inventoryCode,
            primaryButton: {
              text: primaryButtonText,
              callToActionUrl: primaryButton.callToActionUrl,
              disabled:
                !isPaymentsAllEnabled || !isPaymentsCheckoutEnabled || state === IN_CART ? false : lockedDownModal,
            },
            subText,
            secondaryButton: {
              text: secondaryButtonText,
            },
            isReservationAllowed,
            isPpg,
            lockedDownModal,
            lockdownMessage,
            gifLockdown: {
              gifCallToActionUrl: getCmsLabel(sections, 'guestInformationFormButton', 'callToActionUrl'),
              gifCallToActionTitle: getCmsLabel(sections, 'guestInformationFormButton', 'title'),
              gifValidationErrorBody: getCmsLabel(sections, 'ppgGuestInformationFormTxt', 'title'),
              gifIncompleteLockdown,
            },
            sideContentData,
            subtitle: subtitle || subTitle, // CMSv2
            reference,
          };
        }
      )
    ),
    getMobileAppModalData: new Selector(({ getPageContent }) =>
      createSelector([getLabels, getModalData, getPageContent], (labels, { id: modalId }, content) => {
        const defaultValue = {
          title: '',
          sections: [],
        };
        if (!modalId) {
          return defaultValue;
        }
        const findCard = (element) => element.id === modalId && element.primaryButtonUrl === '#MobileAppModal';
        const findSection = (section) => section.cards.find(findCard);
        const cards = get(content.sections.find(findSection), 'cards', null);

        if (!cards) {
          return defaultValue;
        }

        const mobileAppCard = cards.find(findCard) || null;
        if (!mobileAppCard) {
          return defaultValue;
        }
        const {
          detailedDescription,
          images,
          title,
          alert,
          subtitle,
          subTitle,
          modal: { sections },
        } = mobileAppCard;
        if (!title) {
          return defaultValue;
        }

        const { buttons: { print } = {} } = labels;

        return {
          title,
          alert,
          subtitle: subtitle || subTitle, // CMSv2
          printLabel: print,
          longDescription: detailedDescription,
          images: getCarouselImageArray({
            images: [images],
            imageRatioPriorities: [ONE_BY_ONE],
          }),
          sections: sections.map((section) => ({
            ...section,
            cards: section.cards.map((card) => ({
              ...card,
              subTitle: card.subtitle, // CMSv2 - UI Kit card requires subTitle
              images: getCarouselImageArray({ images: [card.images] }),
            })),
          })),
        };
      })
    ),
    getCustomizableSections: new Selector(({ getPageContent }) =>
      createSelector(
        [
          getBookingDetails,
          getPageContent,
          getVoyageType,
          getDisplayComfortCheckIn,
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.BEFORE_YOU_GO_MISSISSIPPI_CARDS),
          getCountryCodeFromCurrency,
          getMvjFlags,
        ],
        (
          { office, packageType, voyage: { type } },
          content,
          voyageType,
          displayComfortCheckIn,
          beforeYouGoMississippiCards,
          countryCode,
          mvjFlags
        ) => {
          const cardToRemove = [];
          if (countryCode !== COUNTRIES.UNITED_STATES) {
            cardToRemove.push('travelProtection');
          }
          if (voyageType !== VOYAGE_TYPE.MIXED) {
            if (
              content.loaded &&
              [BOOKING_PACKAGE_TYPE.PACKAGE, BOOKING_PACKAGE_TYPE.CRUISE_ONLY].includes(packageType)
            ) {
              cardToRemove.push('transfers');
            }
          }
          if (!displayComfortCheckIn) {
            cardToRemove.push('comfortCheckInCard');
          }

          const { sections, ...newContent } = content;
          newContent.sections = [];

          if (newContent.loaded) {
            const beforeYouGoMessages = get(content, 'sections.0.messages', []);
            const travelRequirementsMessages = get(content, 'sections.1.messages', []);
            const messages = mvjFlags?.showBookYourNextVoyage
              ? beforeYouGoMessages.concat(travelRequirementsMessages)
              : beforeYouGoMessages;

            const beforeYouGoCards = get(content, 'sections.0.cards', []);
            const travelRequirementsCards = get(content, 'sections.1.cards', []);
            const sectionCards = mvjFlags?.showBookYourNextVoyage
              ? beforeYouGoCards.concat(travelRequirementsCards)
              : beforeYouGoCards;

            newContent.sections.push({ cards: [], messages });

            sectionCards?.forEach((card) => {
              let hideCard = false;
              hideCard = cardToRemove.findIndex((r) => card?.reference?.includes(r)) !== -1;

              if (!hideCard && type === VOYAGE_TYPE.MISSISSIPPI) {
                hideCard = !beforeYouGoMississippiCards[office]?.includes(card?.reference);
              }

              if (!hideCard && (!card?.modal.sections || card?.modal?.sections?.length)) {
                newContent.sections[0].cards.push(card);
              }
            });
          }

          return newContent;
        }
      )
    ),
  },
});

const { receiveContent } = beforeYouGoStore.creators;

export const fetchBeforeYouGoPageContent = (tab, refreshData) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const voyageType = getVoyageType(state);
  const {
    comboBookings,
    office,
    currency,
    voyage: { id: voyageId },
  } = bookingDetails;
  const comboIds = comboBookings.map(({ shipCode, invoice }) => `${shipCode}${invoice}`).join('|');
  const encodedCruiseName = encodeURIComponent(bookingDetails?.cruise?.jcrName) || '';
  const url = buildUrl(
    `/beforeYouGo/${office}/${currency}`,
    ['voyageType', 'bookingNumber', 'ship.shipCode', 'cruiseName'],
    {
      ...bookingDetails,
      voyageType,
      cruiseName: encodedCruiseName,
    },
    {
      comboIds,
      voyageId,
    }
  );
  return dispatch(
    getData({
      url,
      store: beforeYouGoStore,
      node: 'content',
      creator: receiveContent,
      refreshData,
    })
  );
};

export default beforeYouGoStore;
