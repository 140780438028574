// TODO: Move error handling (AKA isModalOpen) from common store to modal store. (MR-10330 - Tech debt)
import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import memoize from 'lodash/memoize';
import moment from 'moment';
import { formValueSelector, getFormError, startSubmit, stopSubmit } from 'redux-form';
import { createSelector } from 'reselect';
import { deleteData, getData, postData, putData } from './Api';
import {
  APP_PATHS,
  BOOKING_STATUSES,
  BOOKING_TYPE,
  CART_COUNT_INPUTS,
  COUNTRIES,
  COUNTRY_FLAGS,
  CRUISE_CHECKOUT,
  DEFAULT_DATE_FORMAT,
  FEATURE_RESTRICTED,
  FIVE_BY_FOUR,
  FIVE_BY_TWO,
  FLAGS,
  FLAG_NAMES,
  FOOTER_MODALS,
  FORMS,
  FOUR_BY_ONE,
  GIFT_CODES,
  HEADER_MENUS,
  LOCK_CODES,
  MVJ_FLAG_VARIABLES,
  NO_IMAGE,
  PAYMENT_CART_ID,
  REGIONAL_SHORT_DATES,
  RESERVATION_STATE_KEYS,
  SCHEDULED_PAYMENT_STATE,
  SIGN_UP_MESSAGES,
  TA_DISABLED_MENU_ITEM,
  THREE_BY_TWO,
  USER_TYPES,
  VOYAGE_STATUSES,
  VOYAGE_TYPE,
} from './Constants';
import modalStore, { setViewAndShowModal } from './ModalStore';
import { createPageContentDuck } from './ReusableDucks';
import userStore, { reloadBookings } from './UserStore';
import {
  buildUrl,
  convertStringToStartCase,
  decodeCountryCodeFromCurrency,
  findCountry,
  findImageSrc,
  getCmsLabel,
  getCoreErrorMessage,
  getCountryStateProvs,
  getEvoErrorMessage,
  getImageAttributes,
  getModalType,
  getPhoneLinkByDeviceType,
  goTo,
  isFalse,
  navigateTo,
  prepareHtml,
  replaceCMSTokenWithValue,
  sessionStorageService,
} from './Utils';
import clearMemoizedCache from './Utils/clearMemoizeCache';

const { BYPASS_AGE_RESTRICTION_IDS } = MVJ_FLAG_VARIABLES;
const { PPG_AMENITY_GIFT_CODES, SSBP_AMENITY_GIFT_CODE } = GIFT_CODES;

const {
  creators: { receiveQueryParams },
  selectors: {
    getAuthData,
    getBookingDetails,
    getCartId,
    getCartItems,
    getCountryCodeFromCurrency,
    getIsMississippi,
    getItinerary,
    getItineraryDate,
    getItineraryLabels,
    getVoyageSailingStatus,
    getLoggedInUser,
    getActiveUserBookings,
    getUpdateUserData,
    getLockoutStatus,
    getIsViewOnly,
    getComboBookings,
    isExpedition,
    getIsTaAccount,
    getUserType,
    getVoyageType,
    getBalanceDue,
    getDaysToGo,
    getBalanceDueDate,
    getBookingStatus,
    getLockedCode,
    getFeatureRestricted,
  },
} = userStore;

const { updateCartItemCount } = userStore.creators;

const {
  selectors: { getModalData },
} = modalStore;

const commonStore = createPageContentDuck('common').extend({
  types: [
    'CART_UPDATED',
    'CLEAR_STATE',
    'OPEN_MODAL',
    'CLOSE_MODAL',
    'UPDATE_IMPLICIT_PATH',
    'UPDATE_INTERSTITIAL_VARIABLES',
    'UPDATE_RESERVATION_MODAL_STATE',
    'UPDATE_ADD_BOOKING_STEP',
    'UPDATE_REQUEST_INVOICE_STEP',
    'SET_SIGN_UP_MESSAGE',
    'START_SUBMIT',
    'END_SUBMIT',
    'SET_CONTACT_US_PAGE_CONTENT',
    'SET_VOYAGE_NOT_AVAILABLE',
    'SET_ERROR_PAGE',
    'CLEAR_DINING_DATA',
    'SET_FETCHING_COMMON_CONTENT',
    'SET_CART_REDIRECT_MODIFY_MODAL',
    'SET_OPEN_START_GUIDE',
    'SET_FIRST_TIME_LOG_IN',
    'TRACK_CALL_COUNT',
    'SET_PREVIOUS_PAGE',
    'SET_PAX_1_SUB_WAIVER',
    'SET_PAX_2_SUB_WAIVER',
  ],
  initialState: {
    requestCount: 0,
  },
  reducer: (state, { type, ...action }, { types }) => {
    let requestChange;
    if (type.endsWith('END_REQUEST')) {
      requestChange = -1;
    } else if (type.endsWith('START_REQUEST')) {
      requestChange = 1;
    }
    if (requestChange) {
      return {
        ...state,
        requestCount: Math.max(state.requestCount + requestChange, 0),
      };
    }
    switch (type) {
      case types.OPEN_MODAL:
        return {
          ...state,
          modalId: action.modalId,
          isModalOpen: true,
        };
      case types.CLOSE_MODAL:
        return {
          ...state,
          modalId: action.modalId,
          isModalOpen: false,
        };
      case types.UPDATE_IMPLICIT_PATH:
        return {
          ...state,
          implicitPath: action.path,
        };
      case types.UPDATE_INTERSTITIAL_VARIABLES:
        return {
          ...state,
          preAuth: {
            ...state.preAuth,
            interstitialVariables: action.variables,
          },
        };
      case types.UPDATE_RESERVATION_MODAL_STATE:
        return {
          ...state,
          reservationModal: {
            metadata: action.metadata,
            state: action.state,
          },
        };
      case types.UPDATE_ADD_BOOKING_STEP:
        return {
          ...state,
          addBookingStep: action.step,
        };
      case types.UPDATE_REQUEST_INVOICE_STEP:
        return {
          ...state,
          requestInvoiceStep: action.step,
        };
      case types.SET_SIGN_UP_MESSAGE:
        return {
          ...state,
          signUpMessage: action.message,
        };
      case types.START_SUBMIT:
        return {
          ...state,
          submitting: true,
        };
      case types.END_SUBMIT:
        return {
          ...state,
          submitting: false,
        };
      case types.SET_CONTACT_US_PAGE_CONTENT:
        return {
          ...state,
          contactUsPage: {
            content: action.data,
          },
        };
      case types.SET_VOYAGE_NOT_AVAILABLE:
        return {
          ...state,
          voyageNotAvailPage: {
            content: action.data,
          },
        };
      case types.SET_ERROR_PAGE:
        return {
          ...state,
          isErrorPage: action.payload,
        };
      case types.SET_FETCHING_COMMON_CONTENT:
        return {
          ...state,
          isFetchingCommonContent: action.payload,
        };
      case types.SET_CART_REDIRECT_MODIFY_MODAL:
        return {
          ...state,
          cartRedirectModifyModal: action.payload,
        };
      case types.SET_OPEN_START_GUIDE:
        return {
          ...state,
          openStartGuide: action.payload,
        };
      case types.SET_FIRST_TIME_LOG_IN:
        return {
          ...state,
          firstTimeLogIn: action.payload,
        };
      case types.SET_PAX_1_SUB_WAIVER:
        return {
          ...state,
          pax1SubWaiver: action.payload,
        };
      case types.SET_PAX_2_SUB_WAIVER:
        return {
          ...state,
          pax2SubWaiver: action.payload,
        };
      case types.TRACK_CALL_COUNT:
        const trackedCalls = state?.trackedCalls || {};
        const count = trackedCalls[action.callId] || 0;
        return {
          ...state,
          trackedCalls: {
            ...trackedCalls,
            [action.callId]: count + 1,
          },
        };
      case types.SET_PREVIOUS_PAGE:
        return {
          ...state,
          previousPage: action.payload,
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    clearState: () => ({
      type: types.CLEAR_STATE,
    }),
    handleModalOpen: (modalId) => ({
      type: types.OPEN_MODAL,
      modalId,
    }),
    handleModalClose: () => ({
      type: types.CLOSE_MODAL,
      modalId: null,
    }),
    updateImplicitPath: (path) => ({
      type: types.UPDATE_IMPLICIT_PATH,
      path,
    }),
    updateInterstitialVariables: (variables) => ({
      type: types.UPDATE_INTERSTITIAL_VARIABLES,
      variables,
    }),
    updateReservationModalState: (state, metadata) => ({
      type: types.UPDATE_RESERVATION_MODAL_STATE,
      metadata,
      state,
    }),
    updateAddBookingStep: (step) => ({
      type: types.UPDATE_ADD_BOOKING_STEP,
      step,
    }),
    updateRequestInvoiceStep: (step) => ({
      type: types.UPDATE_REQUEST_INVOICE_STEP,
      step,
    }),
    // this is not handled by CommonStore since that doesn't need to be refreshed, but the ShoreEx,
    // Dining, BeforeYouGo, and Air store need to reset their state to force a refresh on next load
    handleSuccessfulCartUpdate: () => ({
      type: types.CART_UPDATED,
    }),
    setSignUpMessage: (message) => ({
      type: types.SET_SIGN_UP_MESSAGE,
      message,
    }),
    setSubmitFlag: (submitting) => ({
      type: submitting ? types.START_SUBMIT : types.END_SUBMIT,
    }),
    setContactPageData: (data) => {
      return {
        type: types.SET_CONTACT_US_PAGE_CONTENT,
        data,
      };
    },
    setVoyageNotAvailPageData: (data) => ({
      type: types.SET_VOYAGE_NOT_AVAILABLE,
      data,
    }),
    setIsErrorPage: (payload) => ({
      type: types.SET_ERROR_PAGE,
      payload,
    }),
    setFetchingCommonContent: (payload) => ({
      type: types.SET_FETCHING_COMMON_CONTENT,
      payload,
    }),
    setCartRedirectModifyModal: (payload) => ({
      type: types.SET_CART_REDIRECT_MODIFY_MODAL,
      payload,
    }),
    setOpenStartGuide: (payload) => ({
      type: types.SET_OPEN_START_GUIDE,
      payload,
    }),
    setFirstTimeLogIn: (payload) => ({
      type: types.SET_FIRST_TIME_LOG_IN,
      payload,
    }),
    setPax1SubWaiver: (payload) => ({
      type: types.SET_PAX_1_SUB_WAIVER,
      payload,
    }),
    setPax2SubWaiver: (payload) => ({
      type: types.SET_PAX_2_SUB_WAIVER,
      payload,
    }),
    trackCallCount: (callId) => ({
      type: types.TRACK_CALL_COUNT,
      callId,
    }),
    setPreviousPage: (payload) => ({
      type: types.SET_PREVIOUS_PAGE,
      payload,
    }),
  }),
  selectors: {
    getEmbarkationDate: (state) => get(state, 'user.bookingDetails.voyage.embarkDate'),
    getDisEmbarkationDate: (state) => get(state, 'user.bookingDetails.voyage.disembarkDate'),
    isModalOpen: (state) => get(state, 'common.isModalOpen', false),
    getCartRedirectModifyModal: (state) => get(state, 'common.cartRedirectModifyModal', ''),
    isErrorPage: (state) => get(state, 'common.isErrorPage', false),
    getSubmitting: (state) => state.common.submitting,
    getSignUpMessage: (state) => get(state, 'common.signUpMessage', SIGN_UP_MESSAGES.SIGN_UP),
    getActiveRequestCount: (state) => state.common.requestCount,
    getBookingPassengers: (state) =>
      formValueSelector(FORMS.BOOKING)(state, 'passenger1', 'passenger2', 'passenger1Time', 'passenger2Time'),
    getLoadedStatus: (state) => get(state, 'common.content.loaded', false),
    getCountries: (state) => get(state, 'common.content.mvjProperties.countries', []),
    getPopularCountries: (state) => get(state, 'common.content.mvjProperties.popularCountries', []),
    getTrackingTools: (state) => get(state, 'common.content.mvjProperties.trackingTools', {}),
    getTitles: (state) => get(state, 'common.content.mvjStrings.labels.titles', []),
    getGenders: (state) => get(state, 'common.content.mvjStrings.labels.genders', []),
    getContactUsPage: (state) => get(state, 'common.contactUsPage.content.sections[0]', {}),
    getVoyageNotAvailablePage: (state) => get(state, 'common.voyageNotAvailPage.content', {}),
    getExcursionsData: (state) => get(state, 'shorex.excursions', {}),
    getTrackedCalls: (state) => state?.common?.trackedCalls || {},
    getPageTabLabels: new Selector(({ getLabels }) =>
      createSelector([getLabels], (labels) =>
        memoize((pageName, tabName) => {
          const { buttons = {}, generic = {}, pages = {} } = labels;
          const pageLabels = get(pages, pageName, {
            labels: {},
            tabs: {},
          });
          if (!tabName) {
            return {
              buttons,
              ...pageLabels,
              labels: {
                ...generic,
                ...pageLabels.labels,
              },
            };
          }
          const tabLabels = get(pageLabels, `tabs.${tabName}`, { labels: {} });
          return {
            buttons,
            ...tabLabels,
            url: `${pageLabels.url}${tabLabels.url}`,
            labels: {
              ...generic,
              ...pageLabels.labels,
              ...tabLabels.labels,
            },
          };
        })
      )
    ),
    getModalLabels: new Selector(({ getLabels }) =>
      createSelector(getLabels, ({ buttons = {}, generic = {}, modals = {} }) =>
        memoize((modalName) => {
          const modalData = get(modals, modalName, { labels: {} });
          return {
            buttons,
            ...modalData,
            labels: {
              ...generic,
              ...modalData.labels,
            },
          };
        })
      )
    ),
    getCountriesPopularFirst: new Selector(({ getCountries, getPopularCountries }) =>
      createSelector([getCountries, getPopularCountries], (countries, popularCountries) =>
        memoize((codeType) => {
          const mappedCountries = popularCountries.map(({ label, ...codes }, index) => {
            const country = {
              label: convertStringToStartCase(label),
              value: codes[codeType],
            };
            if (index === popularCountries.length - 1) {
              country.hasDivider = true;
            }
            return country;
          });

          return countries.reduce((acc, { label, ...codes }) => {
            if (popularCountries.some((item) => item.label === label)) {
              return acc;
            }
            acc.push({
              label: convertStringToStartCase(label),
              value: codes[codeType],
            });
            return acc;
          }, mappedCountries);
        })
      )
    ),
    getFooter: (state) => get(state, 'common.content.footer'),
    getFooterLinks: new Selector(({ getFooter }) =>
      createSelector([getFooter], (footer) => {
        if (!footer) {
          return {
            items: [],
          };
        }
        const mvjFooter = footer;
        if (!mvjFooter || !mvjFooter?.items || typeof mvjFooter !== 'object') {
          return {
            items: [],
          };
        }

        const modalIds = {
          privacy: `#${FOOTER_MODALS.PRIVACY}-modal`,
          contact_us: `#${FOOTER_MODALS.CONTACT}-modal`,
        };

        const oneTrustLinkClasses = {
          'manage-cookies': 'ot-sdk-show-settings',
          cookie_policy: 'ot-sdk-cookie-policy',
        };

        return {
          ...mvjFooter,
          items: mvjFooter.items.map((item) => ({
            ...item,
            modalVersionTwo: true,
            newBrowserTab: [true, 'true'].includes(item.newBrowserTab).toString(),
            text: item.altText,
            modalId: modalIds[item.name] || '',
            className: oneTrustLinkClasses[item.name] || '',
          })),
        };
      })
    ),
    getFooterModalContent: new Selector((selectors) => {
      const { getPageContent, getLabels, getContactUsPage } = selectors;
      return createSelector(
        [getPageContent, getLabels, getBookingDetails, getContactUsPage, getLoggedInUser],
        (commonContent, labels, bookingDetails = {}, contactUsPage, loggedInUser) =>
          memoize((modalType) => {
            const { pastPassenger, office } = bookingDetails;
            const findByReference = (list, reference) => list.find((item) => item.reference.match(reference));
            const getContactModalDataSection = (list, reference1, reference2) => {
              // US and Canada share the same office value, 'US'
              const US_OR_CANADA = office === COUNTRIES.UNITED_STATES;

              // TODO: (MR-6593) remove `false` condition when
              // Viking Explorer Society is ready to launch
              const isPastPassengerUSorCA = false && US_OR_CANADA && pastPassenger;

              const section = !isPastPassengerUSorCA
                ? findByReference(list, reference1)
                : findByReference(list, reference2);

              return section;
            };
            const { items } = contactUsPage;

            const commonContentWithMappedContactUsPageDataLoggedIn = () => {
              if (loggedInUser && items) {
                const contactUsModalData = getContactModalDataSection(
                  items,
                  /^contactUsIntroLabel/g,
                  /^contactUsIntroPastPassengerLabel/g
                );
                const { subtitle: subHeading, title: heading } = contactUsModalData;
                const contactUsImage = findByReference(items, /^contactUsImage/g);
                const { images } = contactUsImage;
                const fiveByFourImage = (images && images.find((image) => image.type === FIVE_BY_FOUR).url) || NO_IMAGE;

                const phoneNumber = replaceCMSTokenWithValue(
                  getCmsLabel(items, 'contactUsIntroLabel', 'longText'),
                  items.map((v) => ({ key: v.reference, value: v.title }))
                );

                const airPhoneNumber = replaceCMSTokenWithValue(
                  getCmsLabel(items, 'contactUsAirPhone', 'longText'),
                  items.map((v) => ({ key: v.reference, value: v.title }))
                );
                const contactUs = {
                  heading,
                  image: fiveByFourImage,
                  title: subHeading,
                  phoneNumber,
                  airPhoneNumber,
                };

                return {
                  ...commonContent,
                  contactUs,
                };
              }

              return null;
            };

            /*
            Since the data between Prelogin and Logged in states are different,
            this mapping is needed.
          */
            const contentWithMappedContactUsPreLoginData = (content) => {
              if (!content) {
                return {};
              }

              const { reference, heading: subHeading, subTitle, subtitle, title: mainHeading } = content; // CMSv2

              if (reference && reference === 'contactUsIntroLabel') {
                const heading = /^contactUsIntroLabel/g;
                const pastPassengerHeading = /^contactUsIntroPastPassengerLabel/g;
                const pastPassengerPhone = /^contactUsPhoneNumberPastPassengerLabel/g;
                const filteredPhoneNumbers = content.numbers.reduce((acc, number) => {
                  if (
                    !number.reference.match(heading) &&
                    !number.reference.match(pastPassengerHeading) &&
                    !number.reference.match(pastPassengerPhone) &&
                    number.countryName
                  ) {
                    acc.push({
                      ...number,
                      number: getPhoneLinkByDeviceType(number.number),
                    });
                  }
                  return acc;
                }, []);
                const contactUs = {
                  ...content,
                  heading: mainHeading,
                  numbers: filteredPhoneNumbers,
                  title: subHeading,
                  bodyText: subtitle,
                  subtitle: subtitle || subTitle, // CMSv2
                };

                return contactUs;
              }

              return content;
            };
            const commonContentModalData = commonContentWithMappedContactUsPageDataLoggedIn() || {};
            let content;
            let mappedContent;

            if (!commonContentModalData[modalType]) {
              content = commonContent[modalType];
              mappedContent = contentWithMappedContactUsPreLoginData(content);
            } else {
              const { bodyText } = commonContentModalData.contactUs;
              /*
              Maps the bodyText key value to the contact us modal data
              if there is no data returned from the /pages/contactUs call
            */
              const commonContentModalDataContactUsMapped = {
                ...commonContentModalData,
                contactUs: {
                  ...commonContentModalData.contactUs,
                  bodyText: commonContentModalData.contactUs.subtitle || commonContentModalData.contactUs.subTitle, // CMSv2
                },
              };

              const commonContentModalFinalData = bodyText
                ? commonContentModalData
                : commonContentModalDataContactUsMapped;

              mappedContent = get(commonContentModalFinalData, modalType);
            }

            if (!mappedContent) {
              return {
                sections: [],
              };
            }

            const image = findImageSrc(mappedContent.imageSet, FIVE_BY_FOUR);

            return {
              printLabel: labels.print,
              image,
              ...mappedContent,
              pastPassenger,
            };
          })
      );
    }),
    getFooterCallViking: new Selector(({ getMvjStrings }) =>
      createSelector(getMvjStrings, (mvjStrings) => {
        return get(mvjStrings, 'footerCallViking', []);
      })
    ),
    getHeroImages: (state) => {
      const foundImages = get(state, 'common.content.heroImages', []);
      const flatten = foundImages.reduce((acc, val) => (val ? acc.concat(val) : acc), []);
      const getImage = (ratio, tier) => {
        const image = flatten.find((img) => img.type === ratio);
        if (getImageAttributes) {
          return image
            ? getImageAttributes({
                image: {
                  ...image,
                  caption: <span dangerouslySetInnerHTML={prepareHtml(image.caption)} />,
                  isFullWidth: true,
                  useFallbackImage: false,
                },
                tier,
                type: ratio,
                ratio,
                fallbackImage: NO_IMAGE,
              })
            : {
                className: 'img-error',
                src: NO_IMAGE,
                ratio,
                tier,
                type: ratio,
                fallbackImage: NO_IMAGE,
              };
        }
        return image;
      };
      const images = [getImage(FOUR_BY_ONE, 'lg'), getImage(FIVE_BY_TWO, 'sm'), getImage(THREE_BY_TWO, 'xs')];
      return images;
    },
    getBackButton: new Selector(({ getLabels }) =>
      createSelector([getVoyageSailingStatus, getLabels], (voyageSailingStatus, labels) => {
        if (labels) {
          const { home } = labels;
          return {
            label: home,
            previousPagePath: voyageSailingStatus,
          };
        }
        return {};
      })
    ),
    getImplicitPath: (state) => get(state, 'common.implicitPath'),
    getLabels: (state) => get(state, 'common.content.mvjStrings.labels', {}),
    getAirClassMap: (state) => get(state, 'common.content.mvjProperties.evoAirClasses', {}), // TODO: UPDATE
    getMvjProperties: (state) => get(state, 'common.content.mvjProperties', {}),
    getMvjStrings: (state) => get(state, 'common.content.mvjStrings', {}), // TODO: Utilize this new selector
    getPassengerTicketContract: (state) => get(state, 'common.content.passengerTicketContract', {}),
    getSSBPPricing: (state) => get(state, 'common.content.ssbpPricing', ''),
    getPaymentsAllEnabled: (state) => get(state, 'common.content.mvjPaymentsAllEnabled', true),
    getPaymentsCheckoutEnabled: (state) => get(state, 'common.content.mvjPaymentsCheckoutEnabled', true),
    getMaintenanceModeActive: (state) => get(state, 'common.content.mvjMaintenanceModeActive', false),
    getCloseToSailingContent: (state) => get(state, 'common.content.closeToSailing', {}),
    getViewOnlyContent: new Selector(({ getLabels }) =>
      createSelector(
        [
          (state) => get(state, 'common.content.viewOnlyContent', {}),
          (state) => get(state, 'common.content.viewOnlyContentCSA', {}),
          (state) => get(state, 'common.content.viewOnlyContentTA', {}),
          getLockedCode,
          getLabels,
          getUserType,
        ],
        (content, csaContent, taContent, lockCode, labels, userType) => {
          let viewOnlyContent = content;
          if (userType === USER_TYPES.CSA && csaContent) {
            viewOnlyContent = csaContent;
          }
          if (userType === USER_TYPES.TA && taContent) {
            viewOnlyContent = taContent;
          }
          const { title, longText, shortText, subtitle } = viewOnlyContent;
          return {
            title,
            bannerMessage: lockCode === LOCK_CODES.LOCK_CODE_01 ? shortText : subtitle,
            modalContent:
              lockCode === LOCK_CODES.LOCK_CODE_02 ? subtitle || labels?.generic?.viewOnlyContentCSATA : longText,
          };
        }
      )
    ),
    getFeatureRestrictedMessage: new Selector(({ getViewOnlyContent, getCloseToSailingContent }) =>
      createSelector(
        [getFeatureRestricted, getViewOnlyContent, getCloseToSailingContent],
        (featureRestricted, viewOnlyContent, closeToSailingContent) => {
          if (featureRestricted === FEATURE_RESTRICTED.VIEW_ONLY) {
            return viewOnlyContent?.bannerMessage;
          }
          if (featureRestricted === FEATURE_RESTRICTED.CLOSE_TO_SAILING) {
            return closeToSailingContent?.shortText;
          }
          return null;
        }
      )
    ),
    getMvjFlags: (state) => get(state, 'common.content.mvjFlags.mvjClient', {}),
    getFlagValue: new Selector(({ getMvjFlags }) =>
      createSelector([getMvjFlags], (mvjFlags) => memoize((flagName) => get(mvjFlags, flagName, null)))
    ),
    getViewOnlyModalContent: new Selector(({ getViewOnlyContent }) =>
      createSelector([getViewOnlyContent], ({ title, modalContent }) => {
        return {
          title,
          subtitle: modalContent,
        };
      })
    ),
    getCloseToSailingModalData: new Selector(({ getCloseToSailingContent }) =>
      createSelector(getCloseToSailingContent, (closeToSailingContent) => {
        const { title, longText } = closeToSailingContent;
        return {
          title,
          body: longText,
        };
      })
    ),
    getTabUrl: new Selector(({ getLabels }) =>
      createSelector(getLabels, (labels) =>
        memoize(
          (pageName, tabName) => get(labels, `pages.${pageName}.tabs.${tabName}.url`, ''),
          (...args) => JSON.stringify(args)
        )
      )
    ),
    getPreAuthProperties: (state) => get(state, 'common.preAuth', {}),
    /**
     * @param {object} getPreAuthProperties
     * @return {getPreAuthInterstitialContent~inner}
     * @summary Grabs the slice of state from common.preAuth based on the route
     */
    getPreAuthInterstitialContent: new Selector(({ getPreAuthProperties, getLabels }) =>
      createSelector([getPreAuthProperties, getLabels], (properties, { pages: { preLogin = {} } }) =>
        memoize((route) => {
          /**
           * @param {string} route
           */
          const { interstitialVariables = [] } = properties;

          const preAuthInterstitalContentMap = {
            [APP_PATHS.EMAIL_EXPIRED]: 'createAccountEmailExpired',
            [APP_PATHS.VERIFY_EMAIL]: 'createAccountVerifyEmail',
            [APP_PATHS.FORGOT_PASSWORD_SENT]: 'resetPasswordEmailSent',
            [APP_PATHS.FORGOT_PASSWORD_EXPIRED]: 'resetPasswordEmailExpired',
            [APP_PATHS.ACCOUNT_CREATED]: 'accountCreated',
            [APP_PATHS.FORGOT_EMAIL_RECOVERED]: 'forgotEmailSent',
            [APP_PATHS.RESET_PASSWORD_SUCCESS]: 'passwordReset',
          };
          const preAuthStateKey = preAuthInterstitalContentMap[route];
          let labels = preLogin[preAuthStateKey];

          if (labels) {
            labels = Object.keys(labels).reduce((acc, key) => {
              acc[key] = replaceCMSTokenWithValue(labels[key], interstitialVariables);
              return acc;
            }, {});
          }
          return {
            labels,
            variables: interstitialVariables,
          };
        })
      )
    ),
    getReservationModalInfo: (state) => get(state, 'common.reservationModal', {}),
    getReservationModalState: new Selector(({ getReservationModalInfo }) =>
      createSelector(getReservationModalInfo, ({ state }) => state)
    ),
    isHeaderContentAvailable: (state) => {
      if (state.common.content) {
        return !!state.common.content.header;
      }
      return false;
    },
    isFooterContentAvailable: (state) => {
      if (state.common.content) {
        return !!state.common.content.footer;
      }
      return false;
    },
    getLogoUrl: (state) => get(state, 'common.content.header.logo[0].url', ''),
    getMenus: (state) => get(state, 'common.content.header.menu'),
    getCommonCards: (state) => get(state, 'home.content.sections[0].cards', []),
    getTermsUrl: (state) => get(state, 'common.content.termsUrl.callToActionUrl'),
    getAddBookingStep: (state) => get(state, 'common.addBookingStep'),
    getRequestInvoiceStep: (state) => get(state, 'common.requestInvoiceStep'),
    getOpenStartGuideValue: (state) => get(state, 'common.openStartGuide'),
    getSubmarineWaiver: (state) => get(state, 'common.content.submarineWaiver'),
    getFirstTimeLogIn: (state) => get(state, 'common.firstTimeLogIn'),
    submarineWaiverVideo: (state) => get(state, 'common.content.submarineWaiverVideo'),
    getReservationHasConflict: (state) => get(state, 'payments.content.sections[0]items[0]'),
    getPax1SubWaiver: (state) => get(state, 'common.pax1SubWaiver'),
    getPax2SubWaiver: (state) => get(state, 'common.pax2SubWaiver'),
    getIsBookingsMadeViaTA: (state) => get(state, 'user.bookingDetails.agent.Evo-UI-Code') === BOOKING_TYPE.TA,
    getPreviousPage: (state) => get(state, 'common.previousPage', null),
    getCountry: new Selector(({ getCountries }) =>
      createSelector(getCountries, (countries) => memoize((countryCode) => findCountry(countries, countryCode)))
    ),
    getCountryStates: new Selector(({ getMvjProperties }) =>
      createSelector(getMvjProperties, (properties) =>
        memoize((countryCode) => getCountryStateProvs(countryCode, properties))
      )
    ),
    getErrors: new Selector(({ getMvjStrings }) =>
      createSelector(
        [getMvjStrings, getCountryCodeFromCurrency, getAuthData],
        ({ errors: commonErrors, preAuthErrors }, countryCode, authData) => {
          const errorMessages = {};
          let errors = commonErrors;

          if (!authData?.account?.username) {
            errors = preAuthErrors;
          }
          if (!errors) {
            return errorMessages;
          }
          Object.entries(errors).forEach(([errorCode, messages]) => {
            const { message } =
              messages.find((m) => {
                const { countries } = m;
                return !countries || countries.includes(countryCode);
              }) || {};
            errorMessages[errorCode] = message;
          });

          return errorMessages;
        }
      )
    ),
    getEvolutionErrors: new Selector(({ getMvjStrings }) =>
      createSelector(getMvjStrings, (mvjStrings) => {
        const errors = get(mvjStrings, 'errors.evoErrors', []);
        return errors.reduce((acc, err) => {
          const { code, message } = err;
          return { ...acc, [code]: message };
        }, {});
      })
    ),
    getCoreErrors: new Selector(({ getMvjStrings }) =>
      createSelector(getMvjStrings, (mvjStrings) => {
        return get(mvjStrings, 'errors.coreErrors', []);
      })
    ),
    getReduxFormError: new Selector(({ getErrors }) =>
      createSelector([(state) => state, getErrors], (state, errors) =>
        memoize(
          (errorCodes, form) => {
            const errorCode = getFormError(form)(state);
            if (!errorCode) {
              return null;
            }
            return errors[errorCodes[errorCode]];
          },
          (...args) => JSON.stringify(args)
        )
      )
    ),
    getItineraryNavigationData: new Selector(({ getIsUKAUNZ, getFlagValue }) =>
      createSelector(
        [getItinerary, getItineraryLabels, getIsUKAUNZ, (state) => getFlagValue(state)(FLAG_NAMES.FILTER_LANDING_DAYS)],
        (itinerary, { day, extension, selectDay }, isUKAUNZ, filterLandingDays) =>
          memoize(
            (navDate, path) => {
              let activeIndex = itinerary.findIndex((i) => i.date === navDate);
              if (activeIndex === -1) {
                activeIndex = 0;
              }

              const days = itinerary.map(({ cityName, localityName, countryName, date, isExtension, isExpeditionLanding }, index) => {
                let dayDescription = null;
                const number = index + 1;

                let selectLineOne = `${day} ${number}`;
                if (isExtension) {
                  dayDescription = extension;
                  selectLineOne += ` ${dayDescription}`;
                }
                selectLineOne += ` \u2013 ${cityName}`;
                const momentDate = moment(date);
                const dateFormat = isUKAUNZ ? `dddd, ${REGIONAL_SHORT_DATES.EU}` : `dddd, ${REGIONAL_SHORT_DATES.NA}`;

                return {
                  action: goTo(`${path}/${date}`),
                  active: index === activeIndex,
                  city: cityName,
                  localityName,
                  country: countryName,
                  date,
                  description: dayDescription,
                  displayDate: momentDate.format(dateFormat),
                  heading: day,
                  label: [
                    selectLineOne,
                    momentDate.format(isUKAUNZ ? `ddd, ${REGIONAL_SHORT_DATES.EU}` : `ddd, ${REGIONAL_SHORT_DATES.NA}`),
                  ],
                  number,
                  isExpeditionLanding: filterLandingDays ? isExpeditionLanding : false,
                };
              });

              return {
                selectPlaceholder: selectDay,
                days,
              };
            },
            (...args) => JSON.stringify(args)
          )
      )
    ),
    getMenuItems: new Selector(({ getFlagValue, getMenus }) =>
      createSelector(
        getMenus,
        getVoyageSailingStatus,
        getLockoutStatus,
        getIsViewOnly,
        isExpedition,
        getIsTaAccount,
        getBookingDetails,
        (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.BOOKING_PAYMENTS_TAB_FLAG),
        (
          menus = [],
          sailingStatus,
          lockout,
          isViewOnly,
          expedition,
          isTaAccount,
          bookingDetails,
          bookingPaymentsTabFlag
        ) =>
          memoize((menuName) => {
            const menu = menus.find((element) => element.menuName === menuName);
            if (!menu) {
              return null;
            }
            if (
              sailingStatus !== VOYAGE_STATUSES.CANCELED &&
              lockout &&
              !isViewOnly &&
              menuName === HEADER_MENUS.LEFT
            ) {
              return [];
            }
            let navigationMenu = menu.items;

            if (sailingStatus !== VOYAGE_STATUSES.FUTURE || sailingStatus === VOYAGE_STATUSES.COMING_SOON) {
              navigationMenu = menu.items.filter((item) => !isFalse(item.isCruiseSailing));
            }
            if (isViewOnly) {
              navigationMenu = menu.items.filter((item) => !isFalse(item.isViewOnly));
            }
            if (expedition) {
              navigationMenu = navigationMenu.filter((item) => !isFalse(item.forExpedition));
            }
            if (isTaAccount) {
              navigationMenu = navigationMenu.filter(
                (item) =>
                  !isFalse(item.forTaUsers) && ![TA_DISABLED_MENU_ITEM.ADD_BOOKING].includes(item['data-target'])
              );
            }

            const isDirect = bookingDetails?.agent?.['Evo-UI-Code'] === BOOKING_TYPE.DIRECT;
            if (isTaAccount || isDirect) {
              const paymentsCartMenuItem = navigationMenu.find((item) => item.id === PAYMENT_CART_ID.NAVIGATION_MENU);
              if (paymentsCartMenuItem && bookingPaymentsTabFlag) {
                paymentsCartMenuItem.url = APP_PATHS.CRUISE_PAYMENTS;
              }
            }

            return navigationMenu;
          })
      )
    ),
    getMenuBookings: new Selector(() =>
      createSelector([getActiveUserBookings], (bookings = []) => {
        if (bookings.length <= 1) {
          return [];
        }
        return bookings.reduce((acc, booking) => {
          if (!booking?.notReadyForMVJ && !booking?.pastBooking) {
            acc.push({
              id: booking.bookingId,
              title: `${booking.cruiseName} \u2013 ${moment(booking.departureDate).format('MMM YYYY')}`,
              isCruiseSailing: 'true',
              isSmallText: 'false',
              openInNewWindow: 'false',
              url: APP_PATHS.INDEX,
            });
          }

          return acc;
        }, []);
      })
    ),
    getProperty: new Selector(({ getMvjProperties }) =>
      createSelector(getMvjProperties, (properties) => memoize((property) => properties[property]))
    ),
    /*
     * getUpdateBookingData always returns the first leg's voyageId/stateroomCategory/stateroomNumber which may not be desired
     * if you are making a reservation for the 2nd leg, etc.
     */
    getUpdateBookingData: new Selector(() =>
      createSelector(
        getBookingDetails,
        ({
          attn,
          email,
          packageType,
          passengers,
          rateCode,
          ship: { stateroomCategory, stateroomNumber },
          voyage: { id },
          comboBookings,
        }) => ({
          attn,
          email,
          numberOfPassengers: passengers.length,
          packageType,
          passengers: passengers.map(
            ({
              birthDate,
              cchid,
              email: passengerEmail,
              firstName,
              gender,
              hasInsurance,
              middle,
              lastName,
              passengerNumber,
              passengerPreferences,
              title,
              addOns,
            }) => ({
              birthDate,
              cchid,
              departureAirClass: '',
              departureAirport: '',
              email: passengerEmail,
              firstName,
              gender,
              hasInsurance,
              middleName: middle,
              lastName,
              passengerNumber,
              passengerPreferences,
              returnAirClass: '',
              returnAirport: '',
              suffix: '',
              title,
              addOns,
            })
          ),
          rateCode,
          sendInvoice: 'N',
          stateroomCategory,
          stateroomNumber,
          voyageId: id,
          voyageIds: comboBookings.map((booking) => booking.voyageId),
        })
      )
    ),

    getExcursionVoyageId: new Selector(({ getExcursionsData }) =>
      createSelector([getExcursionsData], (excursionsData) =>
        memoize((date) => {
          const excursionDay = get(excursionsData, `[${date}]`, {});
          const { voyageId } = excursionDay;
          return voyageId;
        })
      )
    ),
    getMvjPropertiesFeatureFlags: (state) => get(state, 'common.content.mvjProperties.flags', {}),
    getIsCurrentCountryPaymentBlocked: new Selector(({ getFlagValue }) =>
      createSelector(
        [
          getCountryCodeFromCurrency,
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.PAYMENT_DISABLED_COUNTRIES),
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.BLOCK_UK_BOOKING_PAYMENT),
          (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.BLOCK_UK_CART_PAYMENT),
        ],
        (country, blockedCountries, blockUkBookingPayment, blockUkCartPayment) => (type) => {
          const fallbackBlockedStatus = (blockedCountries || []).includes(country);
          if (country === COUNTRIES.UNITED_KINGDOM && type) {
            return type === CRUISE_CHECKOUT ? blockUkBookingPayment : blockUkCartPayment;
          }
          return fallbackBlockedStatus;
        }
      )
    ),
    getIsUK: new Selector(() =>
      createSelector([getCountryCodeFromCurrency], (country) => {
        return country === COUNTRIES.UNITED_KINGDOM;
      })
    ),
    getIsUKAUNZ: new Selector(() =>
      createSelector([getCountryCodeFromCurrency], (country) => {
        return [COUNTRIES.UNITED_KINGDOM, COUNTRIES.AUSTRALIA, COUNTRIES.NEW_ZEALAND].includes(country);
      })
    ),
    getFetchingCommonContent: (state) => get(state, 'common.isFetchingCommonContent', false),
    getIsPpgIncluded: new Selector(() => {
      return createSelector(
        [(state) => getBookingDetails(state)?.promotionalAmenities, () => PPG_AMENITY_GIFT_CODES],
        (promotionalAmenities, ppgGiftCodes) => {
          const isIncluded = (promotionalAmenities || []).some((amenity) =>
            (ppgGiftCodes || []).includes(amenity?.giftCode)
          );
          return isIncluded;
        }
      );
    }),
    getIsSsbpIncludedByAmenityCode: new Selector(() => {
      return createSelector(
        [(state) => getBookingDetails(state)?.promotionalAmenities, () => SSBP_AMENITY_GIFT_CODE],
        (promotionalAmenities, ssbpGiftCode) => {
          const isIncluded = (promotionalAmenities || []).some((amenity) => amenity?.giftCode === ssbpGiftCode);
          return isIncluded;
        }
      );
    }),
    getBypassAgeRestriction: new Selector(({ getFlagValue }) =>
      createSelector(
        [getBookingDetails, (state) => getFlagValue(state)(BYPASS_AGE_RESTRICTION_IDS)],
        ({ voyage: { id } }, bypassAgeRestrictionVoyageIds) => {
          return bypassAgeRestrictionVoyageIds && bypassAgeRestrictionVoyageIds.includes(id);
        }
      )
    ),
    getDisplayComfortCheckIn: new Selector(({ getFlagValue }) =>
      createSelector(
        [getBookingDetails, getVoyageType, (state) => getFlagValue(state)(MVJ_FLAG_VARIABLES.CCI_VOYAGE_IDS)],
        ({ comboBookings, voyage: { id: voyageId, type: brand } }, voyageType, cciVoyageIds) => {
          const flagNameType = comboBookings.length > 0 ? 'combo' : voyageType;
          const flagName = `COMFORT_CHECK_IN_${flagNameType.toUpperCase()}`;
          const flagValue = get(
            FLAGS.find((flag) => flag.name === FLAG_NAMES[flagName]),
            'isActive',
            false
          );
          const validVoyageId = brand === VOYAGE_TYPE.RIVER ? cciVoyageIds?.includes(voyageId) : true;
          return flagValue && validVoyageId;
        }
      )
    ),
    getHasOnboardCreditCard: new Selector(() =>
      createSelector(
        [getBookingDetails, getIsMississippi],
        ({ comboBookings, voyage: { type: voyageType } }, isMississippi) => {
          const isOceanOrExpedition =
            comboBookings.length > 0
              ? comboBookings.some((ship) => [VOYAGE_TYPE.OCEAN, VOYAGE_TYPE.EXPEDITION].includes(ship.voyageType))
              : [VOYAGE_TYPE.OCEAN, VOYAGE_TYPE.EXPEDITION].includes(voyageType);
          return isOceanOrExpedition || isMississippi;
        }
      )
    ),
    getAllowScheduledPayment: new Selector(({ getCountryFlagValue }) =>
      createSelector(
        [
          getBalanceDue,
          getBalanceDueDate,
          getDaysToGo,
          getCountryCodeFromCurrency,
          getUserType,
          getBookingStatus,
          (state) => getCountryFlagValue(state)(COUNTRY_FLAGS.ALLOW_SCHEDULED_PAYMENTS),
        ],
        (balanceDue, balanceDueDate, daysToGo, countryCode, userType, bookingStatus, countryAllowed) => {
          if (!countryAllowed || balanceDue <= 0) {
            return SCHEDULED_PAYMENT_STATE.HIDDEN;
          }
          const dueDateInvalid = moment(balanceDueDate).isBefore(moment());
          if (
            daysToGo < 30 ||
            dueDateInvalid ||
            userType === USER_TYPES.CSA ||
            bookingStatus !== BOOKING_STATUSES.CONFIRMED
          ) {
            return SCHEDULED_PAYMENT_STATE.DISABLED;
          }
          return SCHEDULED_PAYMENT_STATE.ALLOWED;
        }
      )
    ),
    getCountryFlags: new Selector(({ getMvjProperties }) =>
      createSelector([getMvjProperties, getCountryCodeFromCurrency], (mvjProperties, countryCode) => {
        const { countryFlags = {} } =
          mvjProperties?.bookingCountries.find((country) => country.code === countryCode) || {};
        return countryFlags;
      })
    ),
    getCountryFlagValue: new Selector(({ getCountryFlags }) =>
      createSelector([getCountryFlags], (flags) =>
        memoize((flagName) => {
          return flags?.[flagName];
        })
      )
    ),
    getCountryConstants: new Selector(({ getMvjProperties }) =>
      createSelector([getMvjProperties, getCountryCodeFromCurrency], (mvjProperties, countryCode) => {
        const { countryConstants = {} } =
          mvjProperties?.bookingCountries.find((country) => country.code === countryCode) || {};
        return countryConstants;
      })
    ),
    getCountryConstantValue: new Selector(({ getCountryConstants }) =>
      createSelector([getCountryConstants], (constants) =>
        memoize((constantName) => {
          return constants?.[constantName];
        })
      )
    ),
  },
});

const {
  creators: {
    receiveContent,
    setContactPageData,
    setVoyageNotAvailPageData,
    setFetchingCommonContent,
    setPreviousPage,
  },
  selectors: { getBookingPassengers, getCoreErrors, getEvolutionErrors, getUpdateBookingData, getExcursionVoyageId },
} = commonStore;

const receiveCommonContent = (res) => (dispatch) => {
  const { contactUs, ...commonData } = res;
  dispatch(receiveContent(commonData));
  dispatch(setContactPageData(contactUs));
};

export const fetchCommonContent = () => (dispatch, getState) => {
  dispatch(setFetchingCommonContent(true));
  const bookings = getBookingDetails(getState());
  const { cruise, office, currency, voyage: { type = 'river' } = {} } = bookings;
  const voyageNameBase64 = cruise ? window.btoa(cruise.name).replace(/={1,2}$/, '') : '';

  const country = decodeCountryCodeFromCurrency(currency) || office || COUNTRIES.UNITED_STATES;
  const queryParams = { country };
  if (voyageNameBase64) {
    queryParams.cruiseName = voyageNameBase64;
  }

  const url = buildUrl('/start/commonContent', ['type'], { type }, queryParams);

  return dispatch(
    getData({
      url,
      store: commonStore,
      node: 'content',
      refreshData: true,
      creator: receiveCommonContent,
    })
  ).then((res) => {
    clearMemoizedCache();
    sessionStorageService('setItem', 'originCountry', res?.originCountry);
    dispatch(setFetchingCommonContent(false));
    if (res && res.status === 500) {
      navigateTo(APP_PATHS.OOPS_PAGE);
    }
  });
};

export const updateBookingExcursions = ({ payload }) => (dispatch, getState) => {
  const { extensionType, inventoryCode, newPreferences = [], calendar, passengerNumber, excursions, date } = payload;
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const createCalendarItem = getItineraryDate(state)(date)?.isExpeditionLanding !== true;
  const updateBookingPayload = getUpdateBookingData(state);
  const excursionVoyageId = getExcursionVoyageId(state)(date);

  const finalPayload = {
    ...updateBookingPayload,
    updateUserInfo,
    excursions,
    passengers: updateBookingPayload.passengers.map((passenger, index) => {
      const extensions = [];
      if (passenger.passengerNumber === passengerNumber && inventoryCode) {
        const calendarItems = get(calendar, 'items', []);
        const extensionItem = calendarItems.find((item) => item[`forPassenger${passengerNumber}`]) || {};
        extensions.push({
          invoiceCode: extensionItem.inventoryCode || inventoryCode,
          price: extensionItem.pricePerPassenger || 0,
          startTime: extensionItem.startTime || '',
          extensionType,
          voyageId: excursionVoyageId,
        });
      }

      return {
        ...passenger,
        extensions,
        ...newPreferences[index],
      };
    }),
    ...(inventoryCode ? { calendar: payload.calendar } : {}),
    createCalendarItem,
  };

  const bookings = getBookingDetails(state);
  const url = buildUrl('/bookings/excursions', ['office', 'currency', 'bookingNumber', 'date'], {
    ...bookings,
    date,
  });
  return dispatch(
    postData({
      url,
      values: finalPayload,
    })
  ).then((response) => response);
};

export const addExcursionToCart = ({ payload, otherInCart }) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const cartID = getCartId(state);
  const { date } = payload;

  const url = buildUrl(
    '/cart/excursions',
    ['office', 'currency', 'bookingNumber', 'comboVoyageId', 'date'],
    {
      ...bookingDetails,
      comboVoyageId: payload.voyageId,
      date,
    },
    {
      cartID,
      shipId: payload.voyageId?.substr(0, 3),
    }
  );

  return dispatch(
    postData({
      url,
      values: payload,
    })
  ).then((response) => {
    if (!otherInCart) {
      dispatch(updateCartItemCount(CART_COUNT_INPUTS.INCREASE));
    }
    return response;
  });
};

export const removeExcursionFromCart = (payload, date) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);

  const url = buildUrl('/cart/excursions', ['office', 'currency', 'bookingNumber', 'voyage.id', 'date'], {
    ...bookingDetails,
    date,
  });

  return dispatch(
    deleteData({
      url,
      data: payload,
    })
  ).then((response) => response);
};

export const removeIncludedExcursion = ({ onComplete, payload }) => (dispatch, getState) => {
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const bookingDetails = getBookingDetails(state);
  const { extensionType, inventoryCode, newPreferences = [], calendar, passengerNumber, excursions, date } = payload;
  const updateBookingPayload = getUpdateBookingData(state);
  const excursionVoyageId = getExcursionVoyageId(state)(date);

  const finalPayload = {
    ...updateBookingPayload,
    updateUserInfo,
    excursions,
    passengers: updateBookingPayload.passengers.map((passenger, index) => {
      const extensions = [];
      if (passenger.passengerNumber === passengerNumber && inventoryCode) {
        const calendarItems = get(calendar, 'items', []);
        const extensionItem = calendarItems.find((item) => item[`forPassenger${passengerNumber}`]) || {};
        extensions.push({
          invoiceCode: extensionItem.inventoryCode || inventoryCode,
          price: extensionItem.pricePerPassenger || 0,
          startTime: extensionItem.startTime || '',
          extensionType,
          voyageId: excursionVoyageId,
        });
      }

      return {
        ...passenger,
        extensions,
        ...newPreferences[index],
      };
    }),
    ...(inventoryCode ? { calendar: payload.calendar } : {}),
  };

  const url = buildUrl('/bookings/excursions', ['office', 'currency', 'bookingNumber', 'date'], {
    ...bookingDetails,
    date,
  });
  return dispatch(
    deleteData({
      url,
      data: finalPayload,
      creator: onComplete,
    })
  ).then((response) => response);
};

export const updateBooking = ({ onComplete, payload, perStateroom, shouldReloadBookings = true }) => (
  dispatch,
  getState
) => {
  const state = getState();
  const updateUserInfo = getUpdateUserData(state);
  const comboBookings = getComboBookings(state);
  const {
    extensionType,
    inventoryCode,
    newPreferences = [],
    removeExtension = false,
    calendar,
    passengerIndex,
  } = payload;
  const bookingPassengers = perStateroom ? {} : getBookingPassengers(state);
  const updateBookingPayload = getUpdateBookingData(state);

  const finalPayload = {
    ...updateBookingPayload,
    updateUserInfo,
    passengers: updateBookingPayload.passengers.map((passenger, index) => {
      const extensions = [];
      const passengerNumber = index + 1;
      // If the extension applies the whole stateroom, add it to both passengers
      if (
        perStateroom ||
        // If the passenger is selected, and an inventory code is provided,
        // and the extension is not being removed, add it to this passenger
        (bookingPassengers[`passenger${passengerNumber}`] && inventoryCode && !removeExtension) ||
        // If the extension is being removed and the selected passenger index matches,
        // remove it from this passenger
        (removeExtension && passengerIndex === index)
      ) {
        const calendarItems = get(calendar, 'items', []);
        const extensionItem = calendarItems.find((item) => item[`forPassenger${passengerNumber}`]) || {};
        const voyageIds = comboBookings.map((booking) => booking.voyageId);
        const extenstionsBody = {
          invoiceCode: extensionItem.inventoryCode || inventoryCode,
          price: extensionItem.pricePerPassenger || 0,
          extensionType,
          removeExtension,
        };
        if (voyageIds.length > 1) {
          voyageIds.forEach((voyageId) => {
            extensions.push({
              ...extenstionsBody,
              voyageId,
            });
          });
        } else {
          extensions.push({
            ...extenstionsBody,
            voyageId: updateBookingPayload.voyageId,
          });
        }
      }

      return {
        ...passenger,
        extensions,
        ...newPreferences[index],
      };
    }),
    ...(inventoryCode ? { calendar: payload.calendar } : {}),
  };

  const bookings = getBookingDetails(state);
  const url = buildUrl('/bookings', ['office', 'currency', 'bookingNumber'], bookings);
  return dispatch(
    postData({
      url,
      values: finalPayload,
      creator: onComplete,
    })
  ).then((response) => {
    if (shouldReloadBookings && response.isSuccessful) {
      dispatch(reloadBookings());
    }

    return response;
  });
};

export const updateCart = ({ itemId, builtCartItem, overrides }, refreshData = true) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const cartId = getCartId(state);
  let values = {};
  if (builtCartItem) {
    values = builtCartItem;
  } else {
    const cartItems = getCartItems(state);
    // This is remaining for Air Plus items that may need to be updated
    // SSBP and PPG are for both guests, so they are either added or removed, but not updated.
    const cartItem = cartItems.find((item) => item.itemID === itemId);
    values = {
      ...cartItem,
      ...overrides,
    };
  }

  const url = buildUrl('/cart/item', ['office', 'currency', 'cartId', 'itemId', 'calendarId'], {
    ...bookingDetails,
    cartId,
    itemId,
  });
  return dispatch(putData({ url, values })).then((response) => {
    if (response.isSuccessful) {
      if (refreshData) {
        dispatch(reloadBookings());
      }
    } else {
      throw new Error();
    }

    return response;
  });
};

export const addToCart = ({ onComplete, payload, shouldReloadBookings = true }) => (dispatch, getState) => {
  const state = getState();
  const bookingDetails = getBookingDetails(state);
  const { bookingNumber, voyage, calendarId, ship } = bookingDetails;
  const cartID = getCartId(state);
  if (!bookingNumber) {
    return Promise.reject(new Error('Could not find bookingNumber in userStore'));
  }
  const bookingPassengers = getBookingPassengers(state);
  const {
    singlePrice,
    doublePrice,
    extensionType,
    title,
    subtitle,
    subTitle, // CMSv2
    inventoryCode,
    serviceCode,
    unit,
    duration,
    ...overrides
  } = payload;
  const { passenger1, passenger2 } = bookingPassengers;
  const pricePerPassenger = passenger1 && passenger2 ? doublePrice : singlePrice;
  const finalPayload = {
    voyageId: voyage.id,
    forPassenger1: bookingPassengers.passenger1 || false,
    forPassenger2: bookingPassengers.passenger2 || false,
    inventoryCode,
    serviceCode,
    pricePerPassenger,
    extensionType,
    subtitle: subtitle || subTitle, // CMSv2
    title,
    calendarId,
    unit,
    duration,
    ...overrides,
  };

  const url = buildUrl('/cart/addItem', ['office', 'currency', 'bookingNumber', 'voyage.id'], bookingDetails, {
    cartID,
    shipId: get(ship, 'shipCode'),
  });

  return dispatch(
    postData({
      url,
      values: finalPayload,
      creator: onComplete,
    })
  ).then((response) => {
    if (shouldReloadBookings && response.isSuccessful) {
      dispatch(reloadBookings());
    }

    return response;
  });
};

export const getBookingUpdateConfig = (state, store, tab) => {
  const { id: modalId } = getModalData(state);
  const tabContent = tab ? store.selectors.getTabContent(state)(tab) : store.selectors.getPageContent(state);
  const booking = getBookingDetails(state);
  const findCard = (element) => element.id === modalId;
  const findSection = (section) => section.cards.find(findCard);
  const { cards } = tabContent.sections.find(findSection);
  const {
    title,
    details: { comboData = [], inventoryCode, serviceCode, extensionType, singlePrice, fromDate, toDate },
  } = cards.find(findCard);
  const { receiveBookingCartResponse } = store.creators;
  const findPassenger = (passengers, passengerNumber) =>
    !!passengers.find((p) => p.passengerNumber === passengerNumber);
  if (comboData.length > 1) {
    return comboData.map((leg) => ({
      payload: {
        title,
        forPassenger1: findPassenger(booking.passengers, 1),
        forPassenger2: findPassenger(booking.passengers, 2),
        inventoryCode,
        serviceCode,
        extensionType,
        pricePerPassenger: leg.singlePrice,
        startTime: moment(fromDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
        endTime: moment(toDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
        voyageId: leg.voyageId,
      },
      onComplete: receiveBookingCartResponse,
      shouldReloadBookings: false,
    }));
  }

  return {
    payload: {
      title,
      forPassenger1: findPassenger(booking.passengers, 1),
      forPassenger2: findPassenger(booking.passengers, 2),
      inventoryCode,
      serviceCode,
      extensionType,
      pricePerPassenger: singlePrice,
      startTime: moment(fromDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
      endTime: moment(toDate, 'YYYY-MM-DD').format('YYYY-MM-DDTHH:mm:ss'),
    },
    onComplete: receiveBookingCartResponse,
    shouldReloadBookings: false,
  };
};

export const modalAddToCart = (store, tab, refreshFunction) => (dispatch, getState) => {
  dispatch(startSubmit(FORMS.RESERVATION));
  const bookingConfig = getBookingUpdateConfig(getState(), store, tab);

  const markEndOfSubmission = () => dispatch(stopSubmit(FORMS.RESERVATION));

  // if bookingConfig is array, push dispatched add to cart for each payload of combo
  const promises = [];
  if (Array.isArray(bookingConfig) && bookingConfig.length) {
    bookingConfig.forEach((config) => promises.push(dispatch(addToCart(config))));
  } else {
    promises.push(dispatch(addToCart(bookingConfig)));
  }
  return Promise.all(promises)
    .then((response) => {
      if (refreshFunction && (response.isSuccessful || response.every((res) => res.isSuccessful))) {
        return dispatch(reloadBookings()).then(() =>
          dispatch(refreshFunction(tab, true)).then(() => {
            markEndOfSubmission();
            return response;
          })
        );
      }
      return response;
    })
    .catch((response) => {
      markEndOfSubmission();
      return response;
    });
};

export const requestRefund = ({ extensionType, inventoryCode, refreshFunction, tab, updateModalStateFunction }) => (
  dispatch
) => {
  dispatch(startSubmit(FORMS.RESERVATION));
  return dispatch(
    updateBooking({
      payload: {
        extensionType,
        inventoryCode,
        removeExtension: true,
      },
      perStateroom: true,
    })
  ).then((response) => {
    const promises = [];
    if (response.isSuccessful) {
      if (refreshFunction) {
        promises.push(dispatch(refreshFunction(tab, true)));
      }
    }
    return Promise.all(promises).then(() => {
      dispatch(stopSubmit(FORMS.RESERVATION));
      dispatch(updateModalStateFunction(RESERVATION_STATE_KEYS.CANCELED));
      return Promise.resolve(response);
    });
  });
};

export const getEmail = (values) => (dispatch) => {
  const { bookingNumber, firstName, lastName, sailDate } = values;

  const embarkDate = moment(sailDate, DEFAULT_DATE_FORMAT.NA).format('YYYY-MM-DDTHH:mm:ss');

  const queryParams = {
    firstName,
    lastName,
    embarkDate,
  };

  const url = buildUrl('/auth/getemail', ['bookingNumber'], { bookingNumber }, { ...queryParams });
  return dispatch(
    getData({
      url,
      store: commonStore,
    })
  );
};

export const fetchVoyageNotAvailableData = () => (dispatch, getState) => {
  const bookingDetails = getBookingDetails(getState());
  const url = buildUrl('/pages/voyageNotAvailable', ['voyage.type'], { ...bookingDetails });

  return dispatch(
    getData({
      url,
      store: commonStore,
      node: 'voyageNotAvailPage',
      creator: setVoyageNotAvailPageData,
    })
  );
};

export const handleDeepLinkModal = (type, id, queryParamName, shipId = '') => (dispatch) => {
  const paramsObj = {};
  paramsObj[queryParamName] = '';
  dispatch(receiveQueryParams(paramsObj));
  return dispatch(setViewAndShowModal(type, { id, shipId }));
};

const cleanUrlString = (urlString) => {
  return (decodeURIComponent(urlString) || '')
    .replace(/\s/g, '')
    .replace(/[^0-9a-zA-Z]/, '')
    .toLowerCase();
};

const hasPropWithValue = (obj, propNames, propValue) =>
  propNames.some((p) => {
    const propName = obj[p];
    if (propName) {
      return cleanUrlString(propName) === cleanUrlString(propValue);
    }
    return false;
  });

const getSectionCardByProperty = (sections, propNames, propValue) => {
  let modalCard = null;
  sections?.forEach((section) => {
    if (!modalCard) {
      modalCard = section?.cards?.find((card) => hasPropWithValue(card, propNames, propValue));
    }
  });
  return modalCard;
};

const getModalTypeFromCard = (card) => getModalType(card?.primaryButtonUrl);

export const getCardModalByPageName = (sections, modalName, modalTyperFunc = getModalTypeFromCard) => {
  if (modalName && sections) {
    let modalCard = getSectionCardByProperty(sections, ['id'], modalName);
    if (!modalCard) {
      modalCard = getSectionCardByProperty(sections, ['reference'], modalName);
    }
    if (!modalCard) {
      modalCard = getSectionCardByProperty(sections, ['primaryButtonUrl'], modalName);
    }
    if (!modalCard) {
      modalCard = getSectionCardByProperty(sections, ['title'], modalName);
    }
    return modalCard ? { modalType: modalTyperFunc(modalCard), modalId: modalCard.id } : {};
  }
  return null;
};

export const getFriendlyError = (state, data) => {
  const coreErrors = getCoreErrors(state);
  const coreErrorMessage = getCoreErrorMessage(data, coreErrors);
  if (coreErrorMessage) {
    return coreErrorMessage;
  }
  const errors = getEvolutionErrors(state);
  return get(getEvoErrorMessage(data, errors), 'errorDescription', '');
};

export const updatePreviousPage = (path) => (dispatch) => {
  dispatch(setPreviousPage({ from: path }));
};

export const postPassengerSurvey = (paxNumber, surveyCode) => (dispatch, getState) => {
  const state = getState();
  const { bookingNumber, passengers, office, currency } = getBookingDetails(state);
  const updateUserType = getUserType(state);
  const {
    attributes: { sub },
  } = getAuthData(state);

  const { uniqueId } = getAuthData(state);

  const { firstName, lastName, email, airPassengerId } = passengers.find((pass) => pass.passengerNumber === paxNumber);

  const url = buildUrl('/survey/passenger', ['office', 'currency', 'bookingNumber'], {
    office,
    currency,
    bookingNumber,
  });

  return dispatch(
    postData({
      url,
      values: {
        firstName,
        lastName,
        paxNumber,
        passengerId: airPassengerId,
        userId: sub || uniqueId,
        updateUserType,
        email,
        surveyCode,
        surveyFlag: true,
      },
    })
  ).then((res) => res);
};

export const fetchMeta = () => (dispatch) => {
  const url = buildUrl('/meta');
  return dispatch(
    getData({
      url,
      store: commonStore,
    })
  ).then((res) => res);
};

export default commonStore;
