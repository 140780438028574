import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { initializeNonceData, insertAdobeDtmScript, updateAdobeTargetSettings } from './Analytics';
import { fetchMeta } from './CommonStore';
import { insertCspMetaTag } from './Utils/meta';

const mapDispatchToProps = (dispatch) => ({
  getMeta: () => dispatch(fetchMeta()),
});

const AnalyticsSite = ({ children, getMeta }) => {
  useEffect(() => {
    getMeta().then(({ nonce }) => {
      insertCspMetaTag(nonce);
      updateAdobeTargetSettings(nonce);
      insertAdobeDtmScript(nonce);
      initializeNonceData({
        cspNonce: nonce,
      });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return children;
};

AnalyticsSite.propTypes = {
  children: PropTypes.node,
  getMeta: PropTypes.func.isRequired,
};

const enhance = connect(null, mapDispatchToProps);

export default enhance(AnalyticsSite);
