import { connect } from 'react-redux';
import { compose } from 'redux';
import { change, initialize, reduxForm, reset, stopSubmit } from 'redux-form';
import commonStore from '../../../common';
import { APP_PATHS, DEFAULT_DATE_FORMAT, FORMS, MODALS } from '../../../common/Constants';
import modalStore, { closeModalAndNavigate, setViewAndShowModal } from '../../../common/ModalStore';
import { reloadBookings } from '../../../common/UserStore';
import { convertDate } from '../../../common/Utils';
import { withContent, withReinitializeForm } from '../../../components';
import { fetchNotifications } from '../../notifications/NotificationsStore';
import documentsStore, {
  fetchGifContent,
  GIF_DATE_FIELDS,
  gifSelectLoggedInGuest,
  gotoNextLocationIfNeeded,
  submitGif,
} from '../DocumentsStore';
import GuestInfo from './GuestInfo';

const formName = FORMS.GUEST_INFO_FORM;

const dialogModalId = MODALS.DIALOG;

const { clearModal } = modalStore.creators;

const { getBypassAgeRestriction, getIsUKAUNZ, getPreviousPage } = commonStore.selectors;

const {
  creators: { receiveGifData, updateSelectedGuest, updateNextGuest, updateNextLocation },
  selectors: {
    getGifInitialValues,
    getGifPassenger,
    getGifPassengerSelectorData,
    getSelectedGuest,
    getNextGuest,
    getNextLocation,
    getConfirmationModalLabels,
    getGuestSelectorLabels,
    isLoadingGif,
    getCardContent,
  },
} = documentsStore;

const mapStateToProps = (state) => {
  const { title, subtitle } = getCardContent(state)('guestInformationFormCard');
  return {
    initialValues: getGifInitialValues(state),
    isLoading: isLoadingGif(state),
    passengerSelectorData: getGifPassengerSelectorData(state),
    selectedGuest: getSelectedGuest(state),
    isUKAUNZ: getIsUKAUNZ(state),
    dialogModalLabels: getConfirmationModalLabels(state),
    nextLocation: getNextLocation(state),
    guestSelectorLabels: getGuestSelectorLabels(state),
    title,
    subtitle,
    bypassAgeRestriction: getBypassAgeRestriction(state),
  };
};

const handleGuestChange = (selectedGuest) => (dispatch, getState) => {
  dispatch(updateSelectedGuest(selectedGuest));

  const passenger = getGifPassenger(getState())(selectedGuest);
  dispatch(initialize(formName, passenger));
};

const clearSubmitFailed = (values, dispatch, props) => {
  if (props.submitFailed) {
    dispatch(stopSubmit(formName));
  }
};

const handleConfirm = () => (dispatch, getState) => {
  const state = getState();
  const nextGuest = getNextGuest(state);
  const nextLocation = getNextLocation(state);

  if (nextLocation) {
    dispatch(closeModalAndNavigate(nextLocation));
    dispatch(updateNextLocation(null));
  } else if (nextGuest) {
    dispatch(handleGuestChange(nextGuest));
    dispatch(updateNextGuest(null));
    dispatch(clearModal());
  } else {
    dispatch(reset(formName));
    dispatch(clearModal());
  }
};

const mapDispatchToProps = (dispatch) => ({
  fetchContent: () =>
    dispatch(fetchGifContent()).then(() => {
      const previousPath = dispatch((dis, getState) => getPreviousPage(getState()));

      // Don't auto-select the logged in pax when navigating to GIF from these pages
      const whitelist = [APP_PATHS.COMFORT_CHECK_IN, APP_PATHS.COMFORT_CHECK_IN_FORM];
      if (!whitelist.some((path) => path === previousPath?.from)) {
        const selectedPassengerId = dispatch(gifSelectLoggedInGuest());
        dispatch(handleGuestChange(`${selectedPassengerId}`));
      }
    }),
  handleGuestChange: (selectedGuest) => dispatch(handleGuestChange(selectedGuest)),
  handleOnBlur: (props) => clearSubmitFailed({}, dispatch, props),
  handleConfirm: () => dispatch(handleConfirm()),
  updateNextGuest: (number, dirty) => {
    dispatch(updateNextGuest(number));
    if (dirty) {
      dispatch(setViewAndShowModal(dialogModalId));
    }
  },
  handleCancel: (selectedGuest) => {
    dispatch(updateNextLocation(null));
    dispatch(updateNextGuest(null));
    dispatch(change(formName, 'passengerID', `${selectedGuest}`));
    dispatch(clearModal());
  },
  handleBlockedNavigation: ({ pathname }) => {
    dispatch(updateNextLocation(pathname));
    dispatch(setViewAndShowModal(dialogModalId));
    return false;
  },
});

const onSubmit = (formValues, dispatch, { selectedGuest, isUKAUNZ }) => {
  const values = {
    ...formValues,
  };

  GIF_DATE_FIELDS.forEach(({ name }) => {
    const date = values[name];
    if (date) {
      values[name] = convertDate({
        date,
        fromFormat: isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA,
        toFormat: DEFAULT_DATE_FORMAT.YEAR_FIRST,
      });
    }
  });

  return dispatch(submitGif(values, selectedGuest)).then((response) => {
    const { data, isSuccessful } = response;
    if (isSuccessful) {
      const { gif } = data;
      dispatch(gotoNextLocationIfNeeded());
      dispatch(receiveGifData(gif));
      dispatch(fetchNotifications(true));

      const gifValues = {
        ...gif[selectedGuest - 1],
      };

      GIF_DATE_FIELDS.forEach(({ name }) => {
        const date = gifValues[name];
        if (date) {
          gifValues[name] = convertDate({
            date,
            fromFormat: DEFAULT_DATE_FORMAT.YEAR_FIRST,
            toFormat: isUKAUNZ ? DEFAULT_DATE_FORMAT.EU : DEFAULT_DATE_FORMAT.NA,
          });
        }
      });

      dispatch(initialize(formName, gifValues));
      dispatch(handleGuestChange(`${selectedGuest}`));
    }
    dispatch(reloadBookings());
    return response;
  });
};

const enhance = compose(
  connect(mapStateToProps, mapDispatchToProps),
  withContent,
  reduxForm({
    form: formName,
    onSubmit,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
    onChange: clearSubmitFailed,
  }),
  withReinitializeForm
);

export default enhance(GuestInfo);
