import { Selector } from 'extensible-duck';
import get from 'lodash/get';
import { createSelector } from 'reselect';
import commonStore, { modalStore, userStore } from '../../common';
import { getData } from '../../common/Api';
import { ADDITIONS_TYPE, FALLBACK_IMAGE, PAGE_NAMES, TWO_BY_ONE } from '../../common/Constants';
import { createPageTitleDuck } from '../../common/ReusableDucks';
import {
  buildUrl,
  convertStringToStartCase,
  findImageSrc,
  formatMoney,
  getCarouselImageArray,
  isTrue,
  replaceCMSTokenWithValue,
} from '../../common/Utils';

const { getBookingDetails, getCountryCodeFromCurrency, getPassengers } = userStore.selectors;
const {
  selectors: { getLabels, getPageTabLabels },
} = commonStore;
const { getModalData } = modalStore.selectors;
const { PRE, POST } = ADDITIONS_TYPE;
export const isExtensionReserved = (details, passengers) => {
  const { extensionCode, serviceCode } = details.pricing;
  return passengers.every((p) =>
    p.extensions.find((e) => e.invoiceCode === extensionCode && e.serviceCode === serviceCode)
  );
};

export const getExtensionAlert = ({ details = {}, passengers, labels }) => {
  const { roomsAvailable, isSoldOut } = details.pricing;

  if (isExtensionReserved(details, passengers)) {
    return labels.extensionConfirmed;
  }
  if (isSoldOut || roomsAvailable <= 0) {
    return labels.soldOut;
  }
  if (roomsAvailable < 5) {
    return replaceCMSTokenWithValue(roomsAvailable === 1 ? labels.spaceLeft : labels.spacesLeft, [
      {
        key: 'COUNT',
        value: roomsAvailable,
      },
    ]);
  }
  return '';
};

export const getExtensionAvailability = ({ details = {}, labels }) => {
  const { roomsAvailable, isSoldOut } = details.pricing;
  if (isSoldOut || roomsAvailable <= 0) {
    return labels.soldOut;
  }
  if (roomsAvailable < 5) {
    return replaceCMSTokenWithValue(roomsAvailable === 1 ? labels.spaceLeft : labels.spacesLeft, [
      {
        key: 'COUNT',
        value: roomsAvailable,
      },
    ]);
  }
  return '';
};

export const getExtensionsTrip = (
  extensions,
  code,
  countryCode,
  sectionHeader,
  passengers,
  labels,
  buttons,
  bookingDetails
) => {
  const matchedExtensions = extensions.filter((element) => element.type === code);
  if (matchedExtensions.length > 0) {
    const cards = matchedExtensions.map(
      ({ id, secondaryButtonText, subTitle, typeCode, title, tags, details, thumbnailImage, images }) => ({
        id,
        alert: getExtensionAlert({ details, passengers, labels }),
        primaryButtonUrl: '#extensionsModal',
        primaryButton: {
          text: buttons.learnMore,
        },
        secondaryButton: {
          text: secondaryButtonText,
        },
        subtitle: `${formatMoney(details?.priceFrom, 0, countryCode)} | ${subTitle}`,
        subTitleAria: `${formatMoney(details?.priceFrom, 0, countryCode).replace(/[^0-9,]+/g, '')} ${
          bookingDetails?.currency
        } | ${subTitle}`,
        thumbnailUrl: findImageSrc(thumbnailImage, TWO_BY_ONE),
        imageTags: tags.map((tag) => ({
          text: tag,
          className: (tag || '').toLowerCase().replace(/[^a-zA-Z0-9]*/gi, ''),
        })),
        images,
        typeCode,
        title,
        confirmed: isExtensionReserved(details, passengers),
      })
    );
    return {
      cards,
      title: sectionHeader,
      preContent: labels.allPricesPerPerson,
    };
  }
  return {};
};

const extensionsStore = createPageTitleDuck('extensions').extend({
  types: ['RECEIVE_EXTENSIONS'],
  reducer: (state, action, { types }) => {
    switch (action.type) {
      case types.RECEIVE_EXTENSIONS:
        if (action.segment) {
          return {
            ...state,
            data: {
              ...state.data,
              [`${action.segment}Extensions`]: action.payload,
            },
            [`${action.segment}Extensions`]: { loaded: true, loading: false },
          };
        }
        return {
          ...state,
          data: {
            extensions: action.payload,
          },
          extensions: {
            ...state.extensions,
            loaded: true,
          },
        };
      default:
        return state;
    }
  },
  creators: ({ types }) => ({
    receiveExtensions: (payload, segment) => ({
      type: types.RECEIVE_EXTENSIONS,
      payload,
      segment,
    }),
  }),
  selectors: {
    isLoadingExtensions: new Selector(({ getStoreState, isLoadingPage }) =>
      createSelector([getStoreState, isLoadingPage], (storeState, loadingPageStatus) => {
        const extensions = get(storeState, 'extensions');
        let isLoadingExtensions = get(extensions, 'loading', true);
        if (!extensions) {
          const preExtensionsLoading = get(storeState, 'preExtensions.loading', true);
          const postExtensionsLoading = get(storeState, 'postExtensions.loading', true);
          isLoadingExtensions = preExtensionsLoading || postExtensionsLoading;
        }
        return isLoadingExtensions || loadingPageStatus;
      })
    ),
    getExtensions: (state) => {
      let extensions = get(state, 'extensions.data.extensions', []);
      if (!extensions.length) {
        const { preExtensions = [], postExtensions = [] } = get(state, 'extensions.data', {});
        extensions = [...preExtensions, ...postExtensions];
      }
      return extensions;
    },
    getExtensionsCardData: new Selector(({ getExtensions }) =>
      createSelector(
        [
          getExtensions,
          getCountryCodeFromCurrency,
          getPassengers,
          (state) => getPageTabLabels(state)(PAGE_NAMES.EXTENSIONS),
          (state) => getBookingDetails(state),
        ],
        (extensions, countryCode, passengers, { labels, buttons }, bookingDetails) => {
          const preCruise = labels.extensionsPreCruiseSubhead;
          const postCruise = labels.extensionsPostCruiseSubhead;
          const preTripExtensions = getExtensionsTrip(
            extensions,
            'PRE',
            countryCode,
            preCruise,
            passengers,
            labels,
            buttons,
            bookingDetails
          );
          const postTripExtensions = getExtensionsTrip(
            extensions,
            'POST',
            countryCode,
            postCruise,
            passengers,
            labels,
            buttons,
            bookingDetails
          );
          const { cards: preTripCards } = preTripExtensions;
          const { cards: postTripCards } = postTripExtensions;
          const extensionCardData = [];

          if (preTripCards) {
            extensionCardData.push(preTripExtensions);
          }
          if (postTripCards) {
            extensionCardData.push(postTripExtensions);
          }
          return {
            extensionCardData,
            noAvailableSubtitle: labels.noAvailableExtentions,
          };
        }
      )
    ),
    getExtensionsModalData: new Selector(({ getExtensions, getPageContent }) =>
      createSelector(
        [
          getModalData,
          getExtensions,
          (state) => getPageTabLabels(state)(PAGE_NAMES.EXTENSIONS),
          getPageContent,
          getCountryCodeFromCurrency,
          getPassengers,
          (state) => getLabels(state).print,
          (state) => getBookingDetails(state),
        ],
        (
          { id: modalId, type: extensionType },
          extensions,
          { labels },
          pageContent,
          countryCode,
          passengers,
          printLabel,
          bookingDetails
        ) => {
          const findContent = (targetId, targetType) =>
            extensions.find((item) => item.id === targetId && item.typeCode === targetType);
          const modalContent = findContent(modalId, extensionType);
          if (!modalContent) {
            return {
              sideContent: {},
            };
          }
          const { id, subTitle, subtitle, modalSections, images, details } = modalContent;
          const { sections } = pageContent;
          const findCmsItem = (target) => sections[0].items.find((item) => item.reference.match(target));
          const confirmed = isExtensionReserved(details, passengers);
          const messageRegex = new RegExp(/extensionsReservationsPhoneNumber/g);
          const { title } = findCmsItem(messageRegex);

          const bookingAssistance = replaceCMSTokenWithValue(
            title,
            sections[0].items.map((v) => ({ key: v.reference, value: v.longText }))
          );

          const priceFrom = replaceCMSTokenWithValue(labels.priceFrom, [
            {
              key: 'PRICE',
              value: formatMoney(details.priceFrom, 0, countryCode),
            },
          ]);
          const priceFromAria = replaceCMSTokenWithValue(labels.priceFrom, [
            {
              key: 'PRICE',
              value: `${formatMoney(details.priceFrom, 0, countryCode).replace(/[^0-9,]+/g, '')} ${
                bookingDetails?.currency
              }`,
            },
          ]);
          const modalImages = getCarouselImageArray({
            images,
            imageRatioPriorities: [TWO_BY_ONE],
          });
          const findModalSectionContent = (target) =>
            modalSections.find((item) => {
              const regex = new RegExp(target, 'i');
              const reference = get(item, 'reference', '');
              return reference.match(regex);
            });

          const realImages = modalImages.filter((image) => (image?.bucket && image?.imageKey ? image : undefined));
          // carousel needs at least 1 image object to render
          // ratio is needed to set size, and src is needed to error on load
          if (realImages.length === 0) {
            realImages.push({ alt: '', useFallbackImage: true, errorSrc: FALLBACK_IMAGE, type: TWO_BY_ONE });
          }
          const extend = findModalSectionContent('header');
          const overview = findModalSectionContent('overview');
          const hotels = findModalSectionContent('hotels');
          const itinerary = findModalSectionContent('itinerary');
          const disclaimers = findModalSectionContent('disclaimers');
          const modalHeader = extend?.title || '';

          const getExtensionSections = (sections) => {
            return sections.map(({ content, title: sectionTitle }) => {
              return {
                heading: convertStringToStartCase(sectionTitle, true),
                content: (content || [])
                  .filter((c) => !!c)
                  .map((c) => {
                    const description = typeof c === 'string' ? c : c.description;
                    return {
                      heading: isTrue(c.premiumPlus) ? `${c.header} ${labels.premiumPlus}` : c.header,
                      description,
                    };
                  }),
              };
            });
          };
          const extensionsMainSections = getExtensionSections([overview, itinerary, hotels]);
          const extensionsDisclaimerSection = getExtensionSections([disclaimers]);

          return {
            modalId: id,
            subTitle: subTitle || subtitle, // CMSv2
            images: realImages,
            sections: extensionsMainSections,
            disclaimerSection: extensionsDisclaimerSection,
            extend,
            printLabel,
            title: modalHeader,
            subText: priceFrom,
            subTextAria: priceFromAria,
            availability: { text: getExtensionAvailability({ details, labels }) },
            confirmed,
            sideContent: {
              alert: getExtensionAlert({ passengers, details, labels }),
              bookingAssistance,
            },
          };
        }
      )
    ),
  },
});

const mapExtensionsToStore = ({ extensions }, segment) => (dispatch) => {
  const { receiveExtensions } = extensionsStore.creators;
  let validExtensions = extensions;
  if (segment) {
    validExtensions = extensions.filter((extension) => extension.type === segment.toUpperCase());
  }
  return dispatch(receiveExtensions(validExtensions, segment));
};

export const fetchExtensionsPageContent = () => (dispatch, getState) => {
  const { receiveContent } = extensionsStore.creators;
  const bookingDetails = getBookingDetails(getState());

  const { comboBookings } = bookingDetails;
  const dispatchExtensionsCall = ({ ship, segment }) => {
    let voyageId;
    let cruiseName;
    let voyageType;
    if (ship) {
      ({ voyageId, cruiseName, voyageType } = ship);
    } else {
      ({
        voyage: { id: voyageId, type: voyageType },
        cruise: { name: cruiseName },
      } = bookingDetails);
    }
    const voyageNameBase64 = window.btoa(cruiseName).replace(/={1,2}$/, '');
    const extensionDataUrl = `${buildUrl('/extensions', ['office', 'currency', 'voyageId', 'voyageType'], {
      ...bookingDetails,
      voyageId,
      voyageType,
    })}/${voyageNameBase64}`;

    dispatch(
      getData({
        url: extensionDataUrl,
        store: extensionsStore,
        node: segment ? `${segment}Extensions` : 'extensions',
        creator: (data) => dispatch(mapExtensionsToStore(data, segment)),
      })
    );
  };

  // Get pre and post extensions for first and last leg of combo cruise
  if (comboBookings.length > 1) {
    dispatchExtensionsCall({ ship: comboBookings[0], segment: PRE });
    dispatchExtensionsCall({ ship: comboBookings[comboBookings.length - 1], segment: POST });
  } else {
    dispatchExtensionsCall({});
  }
  const extensionContentUrl = buildUrl('/pages/extensions', ['voyage.type'], bookingDetails, {
    country: bookingDetails.office,
  });

  dispatch(
    getData({
      url: extensionContentUrl,
      store: extensionsStore,
      node: 'content',
      creator: receiveContent,
    })
  );
};

export default extensionsStore;
