const fallbackCspWhitelist =
  // eslint-disable-next-line max-len
  '*.liveperson.net *.lpsnmedia.net *.qualtrics.com *.tokenex.com assets.adobedtm.com ats-wrapper.privacymanager.io beacon.krxd.net cdn.cookielaw.org cdn.krxd.net cdn.pdst.fm cdn.tt.omtrdc.net cdnjs.cloudflare.com code.jquery.com connect.facebook.net consumer.krxd.net googleads.g.doubleclick.net launchpad-wrapper.privacymanager.io launchpad.privacymanager.io s.pinimg.com s.yimg.com solutions.invocacdn.com sp.analytics.yahoo.com stackpath.bootstrapcdn.com www.google-analytics.com www.googleadservices.com www.googletagmanager.com';

const insertCspMetaTag = (nonce) => {
  try {
    const scriptSrcContent = nonce
      ? `'self' 'nonce-${nonce}' 'strict-dynamic' ${fallbackCspWhitelist}`
      : `'self' ${fallbackCspWhitelist}`;
    let newCspMetaTag = document.getElementById('metaCsp');
    if (!newCspMetaTag) {
      newCspMetaTag = document.createElement('meta');
      newCspMetaTag.id = 'metaCsp';
      newCspMetaTag.httpEquiv = 'Content-Security-Policy';
      newCspMetaTag.content = `
        worker-src 'self' 'unsafe-inline' file: blob: *;
        script-src ${scriptSrcContent};
        style-src 'self' 'unsafe-inline'
      `;
      document.head.prepend(newCspMetaTag);
    }
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.warn(ex);
  }
};

export { insertCspMetaTag };
